// source: types/questionnaire.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.QuestionnaireAnswer', null, global);
goog.exportSymbol('proto.types.QuestionnaireAnswer.AnswerCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.QuestionnaireAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.types.QuestionnaireAnswer.oneofGroups_);
};
goog.inherits(proto.types.QuestionnaireAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.QuestionnaireAnswer.displayName = 'proto.types.QuestionnaireAnswer';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.QuestionnaireAnswer.oneofGroups_ = [[5,6,7,8]];

/**
 * @enum {number}
 */
proto.types.QuestionnaireAnswer.AnswerCase = {
  ANSWER_NOT_SET: 0,
  BOOL: 5,
  TEXT: 6,
  INT: 7,
  FLOAT: 8
};

/**
 * @return {proto.types.QuestionnaireAnswer.AnswerCase}
 */
proto.types.QuestionnaireAnswer.prototype.getAnswerCase = function() {
  return /** @type {proto.types.QuestionnaireAnswer.AnswerCase} */(jspb.Message.computeOneofCase(this, proto.types.QuestionnaireAnswer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.QuestionnaireAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.types.QuestionnaireAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.QuestionnaireAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.QuestionnaireAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionnaireCompletionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    question: jspb.Message.getFieldWithDefault(msg, 3, ""),
    step: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bool: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    text: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pb_int: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pb_float: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.QuestionnaireAnswer}
 */
proto.types.QuestionnaireAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.QuestionnaireAnswer;
  return proto.types.QuestionnaireAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.QuestionnaireAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.QuestionnaireAnswer}
 */
proto.types.QuestionnaireAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionnaireCompletionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStep(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBool(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.QuestionnaireAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.QuestionnaireAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.QuestionnaireAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.QuestionnaireAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionnaireCompletionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStep();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.types.QuestionnaireAnswer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string questionnaire_completion_id = 2;
 * @return {string}
 */
proto.types.QuestionnaireAnswer.prototype.getQuestionnaireCompletionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.setQuestionnaireCompletionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question = 3;
 * @return {string}
 */
proto.types.QuestionnaireAnswer.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string step = 4;
 * @return {string}
 */
proto.types.QuestionnaireAnswer.prototype.getStep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.setStep = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool bool = 5;
 * @return {boolean}
 */
proto.types.QuestionnaireAnswer.prototype.getBool = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.setBool = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.types.QuestionnaireAnswer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.clearBool = function() {
  return jspb.Message.setOneofField(this, 5, proto.types.QuestionnaireAnswer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.QuestionnaireAnswer.prototype.hasBool = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string text = 6;
 * @return {string}
 */
proto.types.QuestionnaireAnswer.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.setText = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.types.QuestionnaireAnswer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.clearText = function() {
  return jspb.Message.setOneofField(this, 6, proto.types.QuestionnaireAnswer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.QuestionnaireAnswer.prototype.hasText = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 int = 7;
 * @return {number}
 */
proto.types.QuestionnaireAnswer.prototype.getInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.setInt = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.types.QuestionnaireAnswer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.clearInt = function() {
  return jspb.Message.setOneofField(this, 7, proto.types.QuestionnaireAnswer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.QuestionnaireAnswer.prototype.hasInt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double float = 8;
 * @return {number}
 */
proto.types.QuestionnaireAnswer.prototype.getFloat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.setFloat = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.types.QuestionnaireAnswer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.types.QuestionnaireAnswer} returns this
 */
proto.types.QuestionnaireAnswer.prototype.clearFloat = function() {
  return jspb.Message.setOneofField(this, 8, proto.types.QuestionnaireAnswer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.QuestionnaireAnswer.prototype.hasFloat = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.types);
