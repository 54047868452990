
import { userServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import IoUtils, { FileType } from "@/utils/io-utils";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DownloadIPContractRequest } from "zaehlerfreunde-central/user_service_pb";

@Component
export default class SignContract extends Vue {
  downloadContractCall: RemoteCall<UserError> = initialized;
  @Prop() questionnaireCompletionId: string;
  signedContract: File | null = null;

  @Watch("signedContract")
  onSignedContractSelected() {
    this.$emit("contractSelected", this.signedContract);
  }

  async downloadContract() {
    try {
      this.downloadContractCall = pending;

      const request = new DownloadIPContractRequest();
      request.setQuestionnaireCompletionId(this.questionnaireCompletionId);

      const response = await userServiceClient.downloadIpContract(request, {});
      const contract = response.getContract();

      if (contract) {
        IoUtils.fetchFile(
          contract.getPdf()?.getContent() as Uint8Array,
          FileType.PDF,
          contract.getPdf()?.getFileName()
        );
        this.$emit("contractDownloaded", contract);
      }

      this.downloadContractCall = success(void 0);
    } catch (error) {
      this.downloadContractCall = failure(userErrorFrom(error));
    }
  }
}
