import { render, staticRenderFns } from "./AdminLandlordToTenantOverview.vue?vue&type=template&id=cc49d6e4&scoped=true"
import script from "./AdminLandlordToTenantOverview.vue?vue&type=script&lang=ts"
export * from "./AdminLandlordToTenantOverview.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc49d6e4",
  null
  
)

export default component.exports