
import { Vue, Component } from "vue-property-decorator";
import ProjectsTable from "@/components/landlord-tenants-project-table/ProjectsTable.vue";
import CreateLandlordToTenantProjectDialog from "./Dialogs/CreateLandlordToTenantProjectDialog.vue";
@Component({
  components: { ProjectsTable, CreateLandlordToTenantProjectDialog },
})
export default class AdminLandlordToTenantOverview extends Vue {
  showInviteDialog: boolean = false;
  showCreateProjectDialog: boolean = false;
  isRefreshProjects: boolean = false;

  onProjectAdded(value): void {
    this.isRefreshProjects = !this.isRefreshProjects;
  }
}
