
import { Vue, Component, Watch } from "vue-property-decorator";
import ChooseCustomerType, { CustomerTypeInput } from "./ChooseCustomerType.vue";
import CustomerInfo, { CustomerInfoInput } from "./residential/CustomerInfo.vue";
import PreviousContract, { PreviousContractInput } from "./residential/PreviousContract.vue";
import PaymentDetails, { PaymentDetailsInput } from "./residential/PaymentDetails.vue";
import SignContract from "./SignContract.vue";
import ConfirmTariffDetails, { ConfirmTariffDetailsInput } from "./residential/ConfirmTariffDetails.vue";
import SignInStep from "./residential/SignInStep.vue";
import { userServiceClient } from "@/config/service-clients";
import {
  BeginQuestionnaireRequest,
  GetQuestionnaireAnswersRequest,
  SaveQuestionnaireAnswersRequest,
  UploadSignedIpContractRequest,
} from "zaehlerfreunde-central/user_service_pb";
import { QuestionnaireAnswer } from "zaehlerfreunde-proto-types/questionnaire_pb";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import account from "@/store/modules/account";
import { partnerAuthConfig } from "@/config/auth-config";
import { getAuthService } from "@/auth";
import { hasAuthenticated } from "@/utils/login-utils";
import IoUtils from "@/utils/io-utils";
import { paths } from "@/router/routes";
import { CustomerContract } from "zaehlerfreunde-proto-types/file_pb";
import SelectSite, { SiteInput } from "./SelectSite.vue";
import theme, { themeModule } from "@/store/modules/theme";
import { PartnerTheme } from "zaehlerfreunde-proto-types/partners_pb";

type Step =
  | "customer-type"
  | "select-site"
  | "tariff-details"
  | "customer-info"
  | "sign-in"
  | "previous-contract"
  | "payment-details"
  | "sign-contract";

const IP_QUESTIONNAIRE_COMPLETION_ID = "ip-questionnaire-completion";

@Component({
  components: {
    ChooseCustomerType,
    CustomerInfo,
    PreviousContract,
    PaymentDetails,
    SignContract,
    ConfirmTariffDetails,
    SignInStep,
    SelectSite,
  },
})
export default class InnovativePowerQuestionnaire extends Vue {
  @themeModule.State partnerTheme: PartnerTheme | null;
  questionnaireCompletionId = "";

  completedSteps: Step[] = [];
  step: Step = "customer-type";

  customerTypeInput: CustomerTypeInput | null = null;
  confirmTariffDetailsInput: ConfirmTariffDetailsInput | null = null;
  customerInfoInput: CustomerInfoInput | null = null;
  previousContractInput: PreviousContractInput | null = null;
  paymentDetailsInput: PaymentDetailsInput | null = null;
  solarizeSiteInput: SiteInput | null = null;
  signedContract: File | null = null;
  downloadedContract: CustomerContract | null = null;

  isAuthenticated: boolean;

  loadAnswersCall: RemoteCall<UserError> = initialized;
  saveAnswersCall: RemoteCall<UserError> = initialized;

  metaInfo(): { title: string; titleTemplate: string } {
    return {
      title: "Kunde werden",
      titleTemplate: "%s",
    };
  }

  get nextBtnEnabled(): boolean {
    switch (this.step) {
      case "customer-type":
        return !!this.customerTypeInput;
      case "select-site":
        return !!this.solarizeSiteInput;
      case "tariff-details":
        return !!this.confirmTariffDetailsInput;
      case "customer-info":
        return !!this.customerInfoInput;
      case "previous-contract":
        return !!this.previousContractInput;
      case "payment-details":
        return !!this.paymentDetailsInput;
      case "sign-contract":
        return !!this.signedContract;
      default:
        return false;
    }
  }

  get continueBtnText(): string {
    switch (this.step) {
      case "tariff-details":
        return "Angebot akzeptieren";
      case "sign-contract":
        return "Abschließen";
      default:
        return "Weiter";
    }
  }

  get nextStep(): Step | null {
    switch (this.step) {
      case "customer-type":
        return "select-site";
      case "select-site":
        return "tariff-details";
      case "tariff-details":
        return "customer-info";
      case "customer-info":
        if (this.isAuthenticated) {
          return "previous-contract";
        } else {
          return "sign-in";
        }
      case "sign-in":
        return null;
      case "previous-contract":
        return "payment-details";
      case "payment-details":
        return "sign-contract";
      case "sign-contract":
        return "sign-contract";
      default:
        return null;
    }
  }

  get currentAnswers(): QuestionnaireAnswer[] {
    const answerFactory = (question: string) =>
      new QuestionnaireAnswer()
        .setQuestionnaireCompletionId(this.questionnaireCompletionId)
        .setStep(this.step)
        .setQuestion(question);

    switch (this.step) {
      case "customer-type":
        return [
          answerFactory("postcode").setText(this.customerTypeInput?.postcode ?? ""),
          answerFactory("customerType").setText(this.customerTypeInput?.customerType ?? ""),
          answerFactory("yearlyConsumption").setText(this.customerTypeInput?.yearlyConsumption ?? ""),
        ];
      case "tariff-details":
        return [
          answerFactory("readDocuments").setBool(this.confirmTariffDetailsInput?.readDocuments ?? false),
          answerFactory("productNotifications").setBool(this.confirmTariffDetailsInput?.productNotifications ?? false),
          answerFactory("offerNotifications").setBool(this.confirmTariffDetailsInput?.offerNotifications ?? false),
        ];
      case "customer-info":
        return [
          answerFactory("salutation").setText(this.customerInfoInput?.salutation ?? ""),
          answerFactory("title").setText(this.customerInfoInput?.title ?? ""),
          answerFactory("firstName").setText(this.customerInfoInput?.firstName ?? ""),
          answerFactory("lastName").setText(this.customerInfoInput?.lastName ?? ""),
          answerFactory("email").setText(this.customerInfoInput?.email ?? ""),
          answerFactory("phoneNo").setText(this.customerInfoInput?.phoneNo ?? ""),
          answerFactory("street").setText(this.customerInfoInput?.street ?? ""),
          answerFactory("postcode").setText(this.customerInfoInput?.postcode ?? ""),
          answerFactory("city").setText(this.customerInfoInput?.city ?? ""),
          answerFactory("lastYearConsumption").setText(this.customerInfoInput?.lastYearConsumption ?? ""),
          answerFactory("meterReading").setText(this.customerInfoInput?.meterReading ?? ""),
        ];
      case "previous-contract":
        return [
          answerFactory("sameBillingAddress").setBool(this.previousContractInput?.sameBillingAddress ?? false),
          answerFactory("name").setText(this.previousContractInput?.name ?? ""),
          answerFactory("street").setText(this.previousContractInput?.street ?? ""),
          answerFactory("postcode").setText(this.previousContractInput?.postcode ?? ""),
          answerFactory("city").setText(this.previousContractInput?.city ?? ""),
          answerFactory("orderReason").setText(this.previousContractInput?.orderReason ?? ""),
          answerFactory("previousSupplierContract").setText(this.previousContractInput?.previousSupplierContract ?? ""),
          answerFactory("previousSupplier").setText(this.previousContractInput?.previousSupplier ?? ""),
          answerFactory("previousCustomerId").setText(this.previousContractInput?.previousCustomerId ?? ""),
          answerFactory("meterReading").setText(this.previousContractInput?.meterReading ?? ""),
          answerFactory("lastYearConsumption").setText(this.previousContractInput?.lastYearConsumption ?? ""),
          answerFactory("desiredStartDate").setText(this.previousContractInput?.desiredStartDate ?? ""),
        ];
      case "payment-details":
        return [
          answerFactory("accountHolder").setText(this.paymentDetailsInput?.accountHolder ?? ""),
          answerFactory("iban").setText(this.paymentDetailsInput?.iban ?? ""),
          answerFactory("bic").setText(this.paymentDetailsInput?.bic ?? ""),
          answerFactory("sepaConfirmation").setBool(this.paymentDetailsInput?.sepaConfirmation ?? false),
          answerFactory("payPerInvoice").setBool(this.paymentDetailsInput?.payPerInvoice ?? false),
        ];
      case "select-site":
        return [
          answerFactory("siteId").setText(this.solarizeSiteInput?.siteId ?? ""),
          answerFactory("meterId").setText(this.solarizeSiteInput?.meterId ?? ""),
          answerFactory("meterSerial").setText(this.solarizeSiteInput?.meterSerial ?? ""),
        ];
      default:
        return [];
    }
  }

  async mounted(): Promise<void> {
    this.loadAnswersCall = pending;

    try {
      const authConfig = await partnerAuthConfig;
      const partnerId = authConfig.getPartnerId();

      partnerAuthConfig.then((authConfig) => {
        if (authConfig.getPartnerId() !== "ip") {
          this.$router.replace(paths.home);
        }
      });

      await account.getRegistrationStatus(partnerId);
      const authService = getAuthService();
      this.isAuthenticated = await authService.isAuthenticated;

      if (!this.isAuthenticated && hasAuthenticated()) {
        authService.loginWithRedirect({
          appState: {
            targetUrl: this.$route.fullPath,
          },
        });
        return;
      }

      theme.getPartnerTheme(authConfig.getPartnerId());

      if (this.$cookies.isKey(IP_QUESTIONNAIRE_COMPLETION_ID)) {
        this.questionnaireCompletionId = this.$cookies.get(IP_QUESTIONNAIRE_COMPLETION_ID);
      } else {
        const request = new BeginQuestionnaireRequest();
        request.setPartnerId("ip");
        request.setQuestionnaireId("new-customer-onboarding");

        const response = await userServiceClient.beginQuestionnaire(request, {});
        this.questionnaireCompletionId = response.getQuestionnaireCompletionId();

        this.$cookies.set(IP_QUESTIONNAIRE_COMPLETION_ID, response.getQuestionnaireCompletionId());
      }

      const request = new GetQuestionnaireAnswersRequest();
      request.setQuestionnaireCompletionId(this.questionnaireCompletionId);

      const response = await userServiceClient.getQuestionnaireAnswers(request, {});

      const answers: { [step: string]: { [question: string]: QuestionnaireAnswer } } = {};

      response.getAnswersList().forEach((answer) => {
        if (!(answer.getStep() in answers)) {
          answers[answer.getStep()] = {};
        }

        answers[answer.getStep()][answer.getQuestion()] = answer;
      });

      this.setAnswers(answers);

      while (this.nextStep && this.nextBtnEnabled) {
        this.next(false);
      }

      this.loadAnswersCall = success(void 0);
    } catch (error) {
      this.loadAnswersCall = failure(userErrorFrom(error));
    }
  }

  setAnswers(answers: { [step: string]: { [question: string]: QuestionnaireAnswer } }) {
    Object.entries(answers).forEach(([step, stepAnswers]) => {
      switch (step) {
        case "customer-type":
          this.customerTypeInput = {
            customerType: stepAnswers["customerType"].getText() as "residential" | "commercial",
            postcode: stepAnswers["postcode"].getText(),
            yearlyConsumption: stepAnswers["yearlyConsumption"].getText(),
          };
          break;
        case "tariff-details":
          this.confirmTariffDetailsInput = {
            readDocuments: stepAnswers["readDocuments"].getBool(),
            productNotifications: stepAnswers["productNotifications"].getBool(),
            offerNotifications: stepAnswers["offerNotifications"].getBool(),
          };
          break;
        case "customer-info":
          this.customerInfoInput = {
            salutation: stepAnswers["salutation"].getText(),
            title: stepAnswers["title"].getText(),
            firstName: stepAnswers["firstName"].getText(),
            lastName: stepAnswers["lastName"].getText(),
            email: stepAnswers["email"].getText(),
            phoneNo: stepAnswers["phoneNo"].getText(),
            street: stepAnswers["street"].getText(),
            postcode: stepAnswers["postcode"].getText(),
            city: stepAnswers["city"].getText(),
            lastYearConsumption: stepAnswers["lastYearConsumption"].getText(),
            meterReading: stepAnswers["meterReading"].getText(),
          };
          break;
        case "previous-contract":
          this.previousContractInput = {
            sameBillingAddress: stepAnswers["sameBillingAddress"].getBool(),
            name: stepAnswers["name"].getText(),
            street: stepAnswers["street"].getText(),
            postcode: stepAnswers["postcode"].getText(),
            city: stepAnswers["city"].getText(),
            orderReason: stepAnswers["orderReason"].getText(),
            previousSupplierContract: stepAnswers["previousSupplierContract"].getText(),
            previousSupplier: stepAnswers["previousSupplier"].getText(),
            previousCustomerId: stepAnswers["previousCustomerId"].getText(),
            meterReading: stepAnswers["meterReading"].getText(),
            lastYearConsumption: stepAnswers["lastYearConsumption"].getText(),
            desiredStartDate: stepAnswers["desiredStartDate"].getText(),
          };
          break;
        case "payment-details":
          this.paymentDetailsInput = {
            accountHolder: stepAnswers["accountHolder"].getText(),
            iban: stepAnswers["iban"].getText(),
            bic: stepAnswers["bic"].getText(),
            sepaConfirmation: stepAnswers["sepaConfirmation"].getBool(),
            payPerInvoice: stepAnswers["payPerInvoice"].getBool(),
          };
          break;
        case "select-site":
          this.solarizeSiteInput = {
            siteId: stepAnswers["siteId"].getText(),
            meterId: stepAnswers["meterId"].getText(),
            meterSerial: stepAnswers["meterSerial"].getText(),
          };
          break;
      }
    });
  }

  back() {
    const prevStep = this.completedSteps[this.completedSteps.length - 1];
    this.completedSteps = this.completedSteps.slice(0, -1);
    this.step = prevStep;
  }

  async next(saveAnswers: boolean = true) {
    try {
      const answers = this.currentAnswers;

      if (answers && saveAnswers) {
        const request = new SaveQuestionnaireAnswersRequest();
        request.setAnswersList(this.currentAnswers);

        this.saveAnswersCall = pending;
        await userServiceClient.saveQuestionnaireAnswers(request, {});
        this.saveAnswersCall = success(void 0);
      }

      if (this.step === "sign-contract") {
        this.uploadContract();
      }

      const nextStep = this.nextStep;

      if (nextStep) {
        this.completedSteps.push(this.step);
        this.step = nextStep;
      }
    } catch (error) {
      this.saveAnswersCall = failure(userErrorFrom(error));
    }
  }

  async uploadContract() {
    if (this.signedContract !== null && this.downloadedContract !== null) {
      try {
        this.saveAnswersCall = pending;
        const request = new UploadSignedIpContractRequest();
        const contractContent = await IoUtils.readFile(this.signedContract);

        const contract = this.downloadedContract;
        const pdf = contract.getPdf();
        pdf?.setContent(contractContent);
        contract.setPdf(pdf);

        request.setSignedContract(contract);
        request.setQuestionnaireCompletionId(this.questionnaireCompletionId);

        await userServiceClient.uploadSignedIpContract(request, {});

        this.$cookies.remove(IP_QUESTIONNAIRE_COMPLETION_ID);
        this.$router.replace(paths.platform.dashboard);

        this.saveAnswersCall = success(void 0);
      } catch (error) {
        this.saveAnswersCall = failure(userErrorFrom(error));
      }
    }
  }

  @Watch("partnerTheme")
  onPartnerThemeChanged(): void {
    if (this.partnerTheme !== null) {
      var r = document.querySelector(":root") as HTMLElement;
      r.style.setProperty("--zf-font-family", this.partnerTheme.getFontFamily());
      r.style.setProperty("--zf-primary-color", this.partnerTheme.getPrimaryColor());
      r.style.setProperty("--zf-secondary-color", this.partnerTheme.getSecondaryColor());
      r.style.setProperty("--zf-accent-color", this.partnerTheme.getAccentColor());
      r.style.setProperty("--zf-error-color", this.partnerTheme.getErrorColor());
      r.style.setProperty("--zf-info-color", this.partnerTheme.getInfoColor());
      r.style.setProperty("--zf-success-color", this.partnerTheme.getSuccessColor());
      r.style.setProperty("--zf-warning-color", this.partnerTheme.getWarningColor());

      if (this.partnerTheme.getFavicon()) {
        var link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.head.appendChild(link);
        }
        link.href = this.partnerTheme.getFavicon();
      }
    }
  }
}
