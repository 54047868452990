
import { deviceServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GetSolarizeMetersRequest, GetSolarizeSitesRequest } from "zaehlerfreunde-central/device_service_pb";
import { SolarizeMeter, SolarizeSite } from "zaehlerfreunde-proto-types/device_registration_pb";
import { Pagination } from "zaehlerfreunde-proto-types/pagination_pb";

export interface SiteInput {
  siteId: string;
  meterId: string;
  meterSerial: string;
}

@Component
export default class SelectSite extends Vue {
  @Prop() postcode: string;
  @Prop() value: SiteInput | null;
  sites: RemoteData<UserError, SolarizeSite[]> = initialized;
  meters: RemoteData<UserError, SolarizeMeter[]> = initialized;

  selectedSiteId: string | null = null;
  selectedMeterId: string | null = null;

  @Watch("selectedSiteId")
  onSelectedSiteIdChanged() {
    this.getMeters();
  }

  @Watch("selectedMeterId")
  @Watch("meters")
  onSelectedMeterIdChanged() {
    if (this.selectedSiteId && this.selectedMeterId && this.meters.succeeded) {
      const meterSerial = this.meterItems?.find((i) => i.value === this.selectedMeterId)?.text;

      this.$emit("input", {
        siteId: this.selectedSiteId,
        meterId: this.selectedMeterId,
        meterSerial,
      });
    } else {
      this.$emit("input", null);
    }
  }

  mounted() {
    if (this.value) {
      this.selectedSiteId = this.value?.siteId;
      this.selectedMeterId = this.value?.meterId;
    }

    this.getSites();
  }

  get siteItems() {
    return this.sites.data?.map((s) => ({
      value: s.getId(),
      text: `${s.getName()} (${s.getAddress()?.getAddressLineOne()}, ${s.getAddress()?.getPostcode()} ${s
        .getAddress()
        ?.getCity()})`,
    }));
  }

  get meterItems() {
    return this.meters.data?.map((m) => ({
      value: m.getId(),
      text: m.getSerial(),
    }));
  }

  async getSites() {
    try {
      this.sites = pending;

      const request = new GetSolarizeSitesRequest();
      request.setPartnerId("ip");
      request.setPostcode(this.postcode);
      const response = await deviceServiceClient.getSolarizeSites(request, {});

      this.sites = success(response.getSitesList());
    } catch (error) {
      this.sites = failure(userErrorFrom(error));
    }
  }

  async getMeters() {
    try {
      this.meters = pending;

      const request = new GetSolarizeMetersRequest();
      request.setPartnerId("ip");
      request.setSiteId(this.selectedSiteId ?? "");
      request.setOnlyConsumers(true);

      const pagination = new Pagination();
      pagination.setPage(0);
      pagination.setPageSize(100);
      request.setPagination(pagination);

      const response = await deviceServiceClient.getSolarizeMeters(request, {});

      this.meters = success(response.getMetersList());
    } catch (error) {
      this.meters = failure(userErrorFrom(error));
    }
  }
}
