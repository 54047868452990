// source: types/time_data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var types_device_reading_pb = require('../types/device_reading_pb.js');
goog.object.extend(proto, types_device_reading_pb);
goog.exportSymbol('proto.types.Aggregate', null, global);
goog.exportSymbol('proto.types.AggregateType', null, global);
goog.exportSymbol('proto.types.DataPoint', null, global);
goog.exportSymbol('proto.types.FlowData', null, global);
goog.exportSymbol('proto.types.Month', null, global);
goog.exportSymbol('proto.types.MonthYear', null, global);
goog.exportSymbol('proto.types.StockData', null, global);
goog.exportSymbol('proto.types.TimeInterval', null, global);
goog.exportSymbol('proto.types.TimePeriod', null, global);
goog.exportSymbol('proto.types.TimeSeries', null, global);
goog.exportSymbol('proto.types.TimeSeries.DataCase', null, global);
goog.exportSymbol('proto.types.Variable', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.TimeSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.TimeSeries.repeatedFields_, proto.types.TimeSeries.oneofGroups_);
};
goog.inherits(proto.types.TimeSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.TimeSeries.displayName = 'proto.types.TimeSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.StockData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.StockData.repeatedFields_, null);
};
goog.inherits(proto.types.StockData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.StockData.displayName = 'proto.types.StockData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.DataPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.DataPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.DataPoint.displayName = 'proto.types.DataPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.FlowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.FlowData.repeatedFields_, null);
};
goog.inherits(proto.types.FlowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.FlowData.displayName = 'proto.types.FlowData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Aggregate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Aggregate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Aggregate.displayName = 'proto.types.Aggregate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.MonthYear = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.MonthYear, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.MonthYear.displayName = 'proto.types.MonthYear';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.TimeSeries.repeatedFields_ = [5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.types.TimeSeries.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.types.TimeSeries.DataCase = {
  DATA_NOT_SET: 0,
  STOCK_DATA: 1,
  FLOW_DATA: 2
};

/**
 * @return {proto.types.TimeSeries.DataCase}
 */
proto.types.TimeSeries.prototype.getDataCase = function() {
  return /** @type {proto.types.TimeSeries.DataCase} */(jspb.Message.computeOneofCase(this, proto.types.TimeSeries.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.TimeSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.types.TimeSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.TimeSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TimeSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    stockData: (f = msg.getStockData()) && proto.types.StockData.toObject(includeInstance, f),
    flowData: (f = msg.getFlowData()) && proto.types.FlowData.toObject(includeInstance, f),
    variable: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    aggregatesList: jspb.Message.toObjectList(msg.getAggregatesList(),
    proto.types.Aggregate.toObject, includeInstance),
    channel: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.TimeSeries}
 */
proto.types.TimeSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.TimeSeries;
  return proto.types.TimeSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.TimeSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.TimeSeries}
 */
proto.types.TimeSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.StockData;
      reader.readMessage(value,proto.types.StockData.deserializeBinaryFromReader);
      msg.setStockData(value);
      break;
    case 2:
      var value = new proto.types.FlowData;
      reader.readMessage(value,proto.types.FlowData.deserializeBinaryFromReader);
      msg.setFlowData(value);
      break;
    case 3:
      var value = /** @type {!proto.types.Variable} */ (reader.readEnum());
      msg.setVariable(value);
      break;
    case 4:
      var value = /** @type {!proto.types.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 5:
      var value = new proto.types.Aggregate;
      reader.readMessage(value,proto.types.Aggregate.deserializeBinaryFromReader);
      msg.addAggregates(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.TimeSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.TimeSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.TimeSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.TimeSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStockData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.types.StockData.serializeBinaryToWriter
    );
  }
  f = message.getFlowData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.types.FlowData.serializeBinaryToWriter
    );
  }
  f = message.getVariable();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAggregatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.types.Aggregate.serializeBinaryToWriter
    );
  }
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional StockData stock_data = 1;
 * @return {?proto.types.StockData}
 */
proto.types.TimeSeries.prototype.getStockData = function() {
  return /** @type{?proto.types.StockData} */ (
    jspb.Message.getWrapperField(this, proto.types.StockData, 1));
};


/**
 * @param {?proto.types.StockData|undefined} value
 * @return {!proto.types.TimeSeries} returns this
*/
proto.types.TimeSeries.prototype.setStockData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.types.TimeSeries.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.TimeSeries} returns this
 */
proto.types.TimeSeries.prototype.clearStockData = function() {
  return this.setStockData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.TimeSeries.prototype.hasStockData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FlowData flow_data = 2;
 * @return {?proto.types.FlowData}
 */
proto.types.TimeSeries.prototype.getFlowData = function() {
  return /** @type{?proto.types.FlowData} */ (
    jspb.Message.getWrapperField(this, proto.types.FlowData, 2));
};


/**
 * @param {?proto.types.FlowData|undefined} value
 * @return {!proto.types.TimeSeries} returns this
*/
proto.types.TimeSeries.prototype.setFlowData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.types.TimeSeries.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.TimeSeries} returns this
 */
proto.types.TimeSeries.prototype.clearFlowData = function() {
  return this.setFlowData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.TimeSeries.prototype.hasFlowData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Variable variable = 3;
 * @return {!proto.types.Variable}
 */
proto.types.TimeSeries.prototype.getVariable = function() {
  return /** @type {!proto.types.Variable} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.Variable} value
 * @return {!proto.types.TimeSeries} returns this
 */
proto.types.TimeSeries.prototype.setVariable = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Unit unit = 4;
 * @return {!proto.types.Unit}
 */
proto.types.TimeSeries.prototype.getUnit = function() {
  return /** @type {!proto.types.Unit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.Unit} value
 * @return {!proto.types.TimeSeries} returns this
 */
proto.types.TimeSeries.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated Aggregate aggregates = 5;
 * @return {!Array<!proto.types.Aggregate>}
 */
proto.types.TimeSeries.prototype.getAggregatesList = function() {
  return /** @type{!Array<!proto.types.Aggregate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Aggregate, 5));
};


/**
 * @param {!Array<!proto.types.Aggregate>} value
 * @return {!proto.types.TimeSeries} returns this
*/
proto.types.TimeSeries.prototype.setAggregatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.types.Aggregate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Aggregate}
 */
proto.types.TimeSeries.prototype.addAggregates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.types.Aggregate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.TimeSeries} returns this
 */
proto.types.TimeSeries.prototype.clearAggregatesList = function() {
  return this.setAggregatesList([]);
};


/**
 * optional string channel = 6;
 * @return {string}
 */
proto.types.TimeSeries.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.TimeSeries} returns this
 */
proto.types.TimeSeries.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.StockData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.StockData.prototype.toObject = function(opt_includeInstance) {
  return proto.types.StockData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.StockData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.StockData.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.types.DataPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.StockData}
 */
proto.types.StockData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.StockData;
  return proto.types.StockData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.StockData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.StockData}
 */
proto.types.StockData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.types.DataPoint;
      reader.readMessage(value,proto.types.DataPoint.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.StockData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.StockData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.StockData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.StockData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.types.DataPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DataPoint data = 1;
 * @return {!Array<!proto.types.DataPoint>}
 */
proto.types.StockData.prototype.getDataList = function() {
  return /** @type{!Array<!proto.types.DataPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.DataPoint, 1));
};


/**
 * @param {!Array<!proto.types.DataPoint>} value
 * @return {!proto.types.StockData} returns this
*/
proto.types.StockData.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.DataPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.DataPoint}
 */
proto.types.StockData.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.DataPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.StockData} returns this
 */
proto.types.StockData.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.DataPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.types.DataPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.DataPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DataPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    time: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.DataPoint}
 */
proto.types.DataPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.DataPoint;
  return proto.types.DataPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.DataPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.DataPoint}
 */
proto.types.DataPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.DataPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.DataPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.DataPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.DataPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.types.DataPoint.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.DataPoint} returns this
 */
proto.types.DataPoint.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int64 time = 2;
 * @return {number}
 */
proto.types.DataPoint.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.DataPoint} returns this
 */
proto.types.DataPoint.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.FlowData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.FlowData.prototype.toObject = function(opt_includeInstance) {
  return proto.types.FlowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.FlowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.FlowData.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.FlowData}
 */
proto.types.FlowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.FlowData;
  return proto.types.FlowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.FlowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.FlowData}
 */
proto.types.FlowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.FlowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.FlowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.FlowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.FlowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
};


/**
 * repeated double values = 1;
 * @return {!Array<number>}
 */
proto.types.FlowData.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.types.FlowData} returns this
 */
proto.types.FlowData.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.types.FlowData} returns this
 */
proto.types.FlowData.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.FlowData} returns this
 */
proto.types.FlowData.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Aggregate.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Aggregate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Aggregate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Aggregate.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    aggregateType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Aggregate}
 */
proto.types.Aggregate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Aggregate;
  return proto.types.Aggregate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Aggregate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Aggregate}
 */
proto.types.Aggregate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.types.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 3:
      var value = /** @type {!proto.types.AggregateType} */ (reader.readEnum());
      msg.setAggregateType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Aggregate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Aggregate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Aggregate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Aggregate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAggregateType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.types.Aggregate.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.Aggregate} returns this
 */
proto.types.Aggregate.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Unit unit = 2;
 * @return {!proto.types.Unit}
 */
proto.types.Aggregate.prototype.getUnit = function() {
  return /** @type {!proto.types.Unit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.Unit} value
 * @return {!proto.types.Aggregate} returns this
 */
proto.types.Aggregate.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional AggregateType aggregate_type = 3;
 * @return {!proto.types.AggregateType}
 */
proto.types.Aggregate.prototype.getAggregateType = function() {
  return /** @type {!proto.types.AggregateType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.AggregateType} value
 * @return {!proto.types.Aggregate} returns this
 */
proto.types.Aggregate.prototype.setAggregateType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.MonthYear.prototype.toObject = function(opt_includeInstance) {
  return proto.types.MonthYear.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.MonthYear} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MonthYear.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.MonthYear}
 */
proto.types.MonthYear.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.MonthYear;
  return proto.types.MonthYear.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.MonthYear} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.MonthYear}
 */
proto.types.MonthYear.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.Month} */ (reader.readEnum());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.MonthYear.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.MonthYear.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.MonthYear} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.MonthYear.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Month month = 1;
 * @return {!proto.types.Month}
 */
proto.types.MonthYear.prototype.getMonth = function() {
  return /** @type {!proto.types.Month} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.Month} value
 * @return {!proto.types.MonthYear} returns this
 */
proto.types.MonthYear.prototype.setMonth = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.types.MonthYear.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.types.MonthYear} returns this
 */
proto.types.MonthYear.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.types.Variable = {
  CONSUMPTION_THROUGHPUT: 0,
  INGESTION_THROUGHPUT: 1,
  CONSUMPTION_AMOUNT: 2,
  INGESTION_AMOUNT: 3,
  COST: 4,
  EARNINGS: 5,
  EMISSIONS: 6,
  MARKET_PRICES: 7,
  LOCAL_EXTERNAL_ENERGY_THROUGHPUT: 8,
  LOCAL_EXTERNAL_ENERGY_AMOUNT: 9,
  LOCAL_ENERGY: 10,
  EXTERNAL_ENERGY: 11,
  CONSUMPTION_READINGS: 12,
  INGESTION_READINGS: 13,
  BIDIRECTIONAL_THROUGHPUT: 17,
  PROJECTED_CONSUMPTION_AMOUNT: 18,
  PROJECTED_INGESTION_AMOUNT: 19,
  PROJECTED_COST: 20,
  PROJECTED_EARNINGS: 21,
  PROJECTED_EMISSIONS: 22,
  STATE_OF_CHARGE: 23,
  CONSUMPTION_PER_EMPLOYEE: 24,
  CONSUMPTION_PER_SQM: 25,
  LOAD_DURATION: 26
};

/**
 * @enum {number}
 */
proto.types.TimeInterval = {
  HOUR: 0,
  DAY: 1,
  MONTH: 2,
  CUSTOM: 3,
  QUARTER_HOUR: 4
};

/**
 * @enum {number}
 */
proto.types.AggregateType = {
  SUM: 0,
  AVG_MARKET_PRICE_PER_UNIT: 1,
  AVG_PERSONAL_PRICE_PER_UNIT: 2,
  LOCAL_ENERGY_SHARE: 3,
  LAST_VALUE: 4,
  AVG: 5
};

/**
 * @enum {number}
 */
proto.types.TimePeriod = {
  LAST_MONTH: 0,
  LAST_YEAR: 1
};

/**
 * @enum {number}
 */
proto.types.Month = {
  UNDEFINED: 0,
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12
};

goog.object.extend(exports, proto.types);
