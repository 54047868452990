
import { getAuthService } from "@/auth";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class SignInStep extends Vue {
  signIn(isSignUp: boolean = false) {
    const authService = getAuthService();

    authService.loginWithRedirect({
      appState: {
        targetUrl: this.$route.fullPath,
      },
      authorizationParams: { screen_hint: isSignUp ? "signup" : undefined },
    });
  }
}
