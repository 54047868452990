/**
 * @fileoverview gRPC-Web generated client stub for services.central
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as services_central_partner_service_pb from '../../services/central/partner_service_pb';


export class PartnerServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetPartner = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetPartner',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetPartnerRequest,
    services_central_partner_service_pb.GetPartnerResponse,
    (request: services_central_partner_service_pb.GetPartnerRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetPartnerResponse.deserializeBinary
  );

  getPartner(
    request: services_central_partner_service_pb.GetPartnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetPartnerResponse>;

  getPartner(
    request: services_central_partner_service_pb.GetPartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetPartnerResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetPartnerResponse>;

  getPartner(
    request: services_central_partner_service_pb.GetPartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetPartnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetPartner',
        request,
        metadata || {},
        this.methodInfoGetPartner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetPartner',
    request,
    metadata || {},
    this.methodInfoGetPartner);
  }

  methodInfoGetPartners = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetPartners',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetPartnersRequest,
    services_central_partner_service_pb.GetPartnersResponse,
    (request: services_central_partner_service_pb.GetPartnersRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetPartnersResponse.deserializeBinary
  );

  getPartners(
    request: services_central_partner_service_pb.GetPartnersRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetPartnersResponse>;

  getPartners(
    request: services_central_partner_service_pb.GetPartnersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetPartnersResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetPartnersResponse>;

  getPartners(
    request: services_central_partner_service_pb.GetPartnersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetPartnersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetPartners',
        request,
        metadata || {},
        this.methodInfoGetPartners,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetPartners',
    request,
    metadata || {},
    this.methodInfoGetPartners);
  }

  methodInfoAddPartner = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/AddPartner',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.AddPartnerRequest,
    services_central_partner_service_pb.AddPartnerResponse,
    (request: services_central_partner_service_pb.AddPartnerRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.AddPartnerResponse.deserializeBinary
  );

  addPartner(
    request: services_central_partner_service_pb.AddPartnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.AddPartnerResponse>;

  addPartner(
    request: services_central_partner_service_pb.AddPartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.AddPartnerResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.AddPartnerResponse>;

  addPartner(
    request: services_central_partner_service_pb.AddPartnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.AddPartnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/AddPartner',
        request,
        metadata || {},
        this.methodInfoAddPartner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/AddPartner',
    request,
    metadata || {},
    this.methodInfoAddPartner);
  }

  methodInfoGetPartnerTheme = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetPartnerTheme',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetPartnerThemeRequest,
    services_central_partner_service_pb.GetPartnerThemeResponse,
    (request: services_central_partner_service_pb.GetPartnerThemeRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetPartnerThemeResponse.deserializeBinary
  );

  getPartnerTheme(
    request: services_central_partner_service_pb.GetPartnerThemeRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetPartnerThemeResponse>;

  getPartnerTheme(
    request: services_central_partner_service_pb.GetPartnerThemeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetPartnerThemeResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetPartnerThemeResponse>;

  getPartnerTheme(
    request: services_central_partner_service_pb.GetPartnerThemeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetPartnerThemeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetPartnerTheme',
        request,
        metadata || {},
        this.methodInfoGetPartnerTheme,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetPartnerTheme',
    request,
    metadata || {},
    this.methodInfoGetPartnerTheme);
  }

  methodInfoGetSocialLinks = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetSocialLinks',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetSocialLinksRequest,
    services_central_partner_service_pb.GetSocialLinksResponse,
    (request: services_central_partner_service_pb.GetSocialLinksRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetSocialLinksResponse.deserializeBinary
  );

  getSocialLinks(
    request: services_central_partner_service_pb.GetSocialLinksRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetSocialLinksResponse>;

  getSocialLinks(
    request: services_central_partner_service_pb.GetSocialLinksRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetSocialLinksResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetSocialLinksResponse>;

  getSocialLinks(
    request: services_central_partner_service_pb.GetSocialLinksRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetSocialLinksResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetSocialLinks',
        request,
        metadata || {},
        this.methodInfoGetSocialLinks,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetSocialLinks',
    request,
    metadata || {},
    this.methodInfoGetSocialLinks);
  }

  methodInfoGetAuthConfig = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetAuthConfig',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetAuthConfigRequest,
    services_central_partner_service_pb.GetAuthConfigResponse,
    (request: services_central_partner_service_pb.GetAuthConfigRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetAuthConfigResponse.deserializeBinary
  );

  getAuthConfig(
    request: services_central_partner_service_pb.GetAuthConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetAuthConfigResponse>;

  getAuthConfig(
    request: services_central_partner_service_pb.GetAuthConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetAuthConfigResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetAuthConfigResponse>;

  getAuthConfig(
    request: services_central_partner_service_pb.GetAuthConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetAuthConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetAuthConfig',
        request,
        metadata || {},
        this.methodInfoGetAuthConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetAuthConfig',
    request,
    metadata || {},
    this.methodInfoGetAuthConfig);
  }

  methodInfoGetCustomizationConfig = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetCustomizationConfig',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetCustomizationConfigRequest,
    services_central_partner_service_pb.GetCustomizationConfigResponse,
    (request: services_central_partner_service_pb.GetCustomizationConfigRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetCustomizationConfigResponse.deserializeBinary
  );

  getCustomizationConfig(
    request: services_central_partner_service_pb.GetCustomizationConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetCustomizationConfigResponse>;

  getCustomizationConfig(
    request: services_central_partner_service_pb.GetCustomizationConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetCustomizationConfigResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetCustomizationConfigResponse>;

  getCustomizationConfig(
    request: services_central_partner_service_pb.GetCustomizationConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetCustomizationConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetCustomizationConfig',
        request,
        metadata || {},
        this.methodInfoGetCustomizationConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetCustomizationConfig',
    request,
    metadata || {},
    this.methodInfoGetCustomizationConfig);
  }

  methodInfoUpdateCustomizationConfig = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/UpdateCustomizationConfig',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.UpdateCustomizationConfigRequest,
    services_central_partner_service_pb.UpdateCustomizationConfigResponse,
    (request: services_central_partner_service_pb.UpdateCustomizationConfigRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.UpdateCustomizationConfigResponse.deserializeBinary
  );

  updateCustomizationConfig(
    request: services_central_partner_service_pb.UpdateCustomizationConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.UpdateCustomizationConfigResponse>;

  updateCustomizationConfig(
    request: services_central_partner_service_pb.UpdateCustomizationConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.UpdateCustomizationConfigResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.UpdateCustomizationConfigResponse>;

  updateCustomizationConfig(
    request: services_central_partner_service_pb.UpdateCustomizationConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.UpdateCustomizationConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/UpdateCustomizationConfig',
        request,
        metadata || {},
        this.methodInfoUpdateCustomizationConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/UpdateCustomizationConfig',
    request,
    metadata || {},
    this.methodInfoUpdateCustomizationConfig);
  }

  methodInfoUpdateTheme = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/UpdateTheme',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.UpdateThemeRequest,
    services_central_partner_service_pb.UpdateThemeResponse,
    (request: services_central_partner_service_pb.UpdateThemeRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.UpdateThemeResponse.deserializeBinary
  );

  updateTheme(
    request: services_central_partner_service_pb.UpdateThemeRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.UpdateThemeResponse>;

  updateTheme(
    request: services_central_partner_service_pb.UpdateThemeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.UpdateThemeResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.UpdateThemeResponse>;

  updateTheme(
    request: services_central_partner_service_pb.UpdateThemeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.UpdateThemeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/UpdateTheme',
        request,
        metadata || {},
        this.methodInfoUpdateTheme,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/UpdateTheme',
    request,
    metadata || {},
    this.methodInfoUpdateTheme);
  }

  methodInfoCreateClientSecret = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/CreateClientSecret',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.CreateClientSecretRequest,
    services_central_partner_service_pb.CreateClientSecretResponse,
    (request: services_central_partner_service_pb.CreateClientSecretRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.CreateClientSecretResponse.deserializeBinary
  );

  createClientSecret(
    request: services_central_partner_service_pb.CreateClientSecretRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.CreateClientSecretResponse>;

  createClientSecret(
    request: services_central_partner_service_pb.CreateClientSecretRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.CreateClientSecretResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.CreateClientSecretResponse>;

  createClientSecret(
    request: services_central_partner_service_pb.CreateClientSecretRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.CreateClientSecretResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/CreateClientSecret',
        request,
        metadata || {},
        this.methodInfoCreateClientSecret,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/CreateClientSecret',
    request,
    metadata || {},
    this.methodInfoCreateClientSecret);
  }

  methodInfoGetClientSecrets = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetClientSecrets',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetClientSecretsRequest,
    services_central_partner_service_pb.GetClientSecretsResponse,
    (request: services_central_partner_service_pb.GetClientSecretsRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetClientSecretsResponse.deserializeBinary
  );

  getClientSecrets(
    request: services_central_partner_service_pb.GetClientSecretsRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetClientSecretsResponse>;

  getClientSecrets(
    request: services_central_partner_service_pb.GetClientSecretsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetClientSecretsResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetClientSecretsResponse>;

  getClientSecrets(
    request: services_central_partner_service_pb.GetClientSecretsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetClientSecretsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetClientSecrets',
        request,
        metadata || {},
        this.methodInfoGetClientSecrets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetClientSecrets',
    request,
    metadata || {},
    this.methodInfoGetClientSecrets);
  }

  methodInfoDeleteClientSecret = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/DeleteClientSecret',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.DeleteClientSecretRequest,
    services_central_partner_service_pb.DeleteClientSecretResponse,
    (request: services_central_partner_service_pb.DeleteClientSecretRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.DeleteClientSecretResponse.deserializeBinary
  );

  deleteClientSecret(
    request: services_central_partner_service_pb.DeleteClientSecretRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.DeleteClientSecretResponse>;

  deleteClientSecret(
    request: services_central_partner_service_pb.DeleteClientSecretRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.DeleteClientSecretResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.DeleteClientSecretResponse>;

  deleteClientSecret(
    request: services_central_partner_service_pb.DeleteClientSecretRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.DeleteClientSecretResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/DeleteClientSecret',
        request,
        metadata || {},
        this.methodInfoDeleteClientSecret,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/DeleteClientSecret',
    request,
    metadata || {},
    this.methodInfoDeleteClientSecret);
  }

  methodInfoCreateApiToken = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/CreateApiToken',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.CreateApiTokenRequest,
    services_central_partner_service_pb.CreateApiTokenResponse,
    (request: services_central_partner_service_pb.CreateApiTokenRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.CreateApiTokenResponse.deserializeBinary
  );

  createApiToken(
    request: services_central_partner_service_pb.CreateApiTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.CreateApiTokenResponse>;

  createApiToken(
    request: services_central_partner_service_pb.CreateApiTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.CreateApiTokenResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.CreateApiTokenResponse>;

  createApiToken(
    request: services_central_partner_service_pb.CreateApiTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.CreateApiTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/CreateApiToken',
        request,
        metadata || {},
        this.methodInfoCreateApiToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/CreateApiToken',
    request,
    metadata || {},
    this.methodInfoCreateApiToken);
  }

  methodInfoGetEmailTemplate = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetEmailTemplate',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetEmailTemplateRequest,
    services_central_partner_service_pb.GetEmailTemplateResponse,
    (request: services_central_partner_service_pb.GetEmailTemplateRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetEmailTemplateResponse.deserializeBinary
  );

  getEmailTemplate(
    request: services_central_partner_service_pb.GetEmailTemplateRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetEmailTemplateResponse>;

  getEmailTemplate(
    request: services_central_partner_service_pb.GetEmailTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetEmailTemplateResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetEmailTemplateResponse>;

  getEmailTemplate(
    request: services_central_partner_service_pb.GetEmailTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetEmailTemplateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetEmailTemplate',
        request,
        metadata || {},
        this.methodInfoGetEmailTemplate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetEmailTemplate',
    request,
    metadata || {},
    this.methodInfoGetEmailTemplate);
  }

  methodInfoSetEmailTemplate = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/SetEmailTemplate',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.SetEmailTemplateRequest,
    services_central_partner_service_pb.SetEmailTemplateResponse,
    (request: services_central_partner_service_pb.SetEmailTemplateRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.SetEmailTemplateResponse.deserializeBinary
  );

  setEmailTemplate(
    request: services_central_partner_service_pb.SetEmailTemplateRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.SetEmailTemplateResponse>;

  setEmailTemplate(
    request: services_central_partner_service_pb.SetEmailTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.SetEmailTemplateResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.SetEmailTemplateResponse>;

  setEmailTemplate(
    request: services_central_partner_service_pb.SetEmailTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.SetEmailTemplateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/SetEmailTemplate',
        request,
        metadata || {},
        this.methodInfoSetEmailTemplate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/SetEmailTemplate',
    request,
    metadata || {},
    this.methodInfoSetEmailTemplate);
  }

  methodInfoValidatePartnerDomain = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/ValidatePartnerDomain',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.ValidatePartnerDomainRequest,
    services_central_partner_service_pb.ValidatePartnerDomainResponse,
    (request: services_central_partner_service_pb.ValidatePartnerDomainRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.ValidatePartnerDomainResponse.deserializeBinary
  );

  validatePartnerDomain(
    request: services_central_partner_service_pb.ValidatePartnerDomainRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.ValidatePartnerDomainResponse>;

  validatePartnerDomain(
    request: services_central_partner_service_pb.ValidatePartnerDomainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.ValidatePartnerDomainResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.ValidatePartnerDomainResponse>;

  validatePartnerDomain(
    request: services_central_partner_service_pb.ValidatePartnerDomainRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.ValidatePartnerDomainResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/ValidatePartnerDomain',
        request,
        metadata || {},
        this.methodInfoValidatePartnerDomain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/ValidatePartnerDomain',
    request,
    metadata || {},
    this.methodInfoValidatePartnerDomain);
  }

  methodInfoGetPartnerDomainStatus = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetPartnerDomainStatus',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetPartnerDomainStatusRequest,
    services_central_partner_service_pb.GetPartnerDomainStatusResponse,
    (request: services_central_partner_service_pb.GetPartnerDomainStatusRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetPartnerDomainStatusResponse.deserializeBinary
  );

  getPartnerDomainStatus(
    request: services_central_partner_service_pb.GetPartnerDomainStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetPartnerDomainStatusResponse>;

  getPartnerDomainStatus(
    request: services_central_partner_service_pb.GetPartnerDomainStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetPartnerDomainStatusResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetPartnerDomainStatusResponse>;

  getPartnerDomainStatus(
    request: services_central_partner_service_pb.GetPartnerDomainStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetPartnerDomainStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetPartnerDomainStatus',
        request,
        metadata || {},
        this.methodInfoGetPartnerDomainStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetPartnerDomainStatus',
    request,
    metadata || {},
    this.methodInfoGetPartnerDomainStatus);
  }

  methodInfoConfirmPartnerDomainCreation = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/ConfirmPartnerDomainCreation',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.ConfirmPartnerDomainCreationRequest,
    services_central_partner_service_pb.ConfirmPartnerDomainCreationResponse,
    (request: services_central_partner_service_pb.ConfirmPartnerDomainCreationRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.ConfirmPartnerDomainCreationResponse.deserializeBinary
  );

  confirmPartnerDomainCreation(
    request: services_central_partner_service_pb.ConfirmPartnerDomainCreationRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.ConfirmPartnerDomainCreationResponse>;

  confirmPartnerDomainCreation(
    request: services_central_partner_service_pb.ConfirmPartnerDomainCreationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.ConfirmPartnerDomainCreationResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.ConfirmPartnerDomainCreationResponse>;

  confirmPartnerDomainCreation(
    request: services_central_partner_service_pb.ConfirmPartnerDomainCreationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.ConfirmPartnerDomainCreationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/ConfirmPartnerDomainCreation',
        request,
        metadata || {},
        this.methodInfoConfirmPartnerDomainCreation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/ConfirmPartnerDomainCreation',
    request,
    metadata || {},
    this.methodInfoConfirmPartnerDomainCreation);
  }

  methodInfoGetSubscribedFeatures = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/GetSubscribedFeatures',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.GetSubscribedFeaturesRequest,
    services_central_partner_service_pb.GetSubscribedFeaturesResponse,
    (request: services_central_partner_service_pb.GetSubscribedFeaturesRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.GetSubscribedFeaturesResponse.deserializeBinary
  );

  getSubscribedFeatures(
    request: services_central_partner_service_pb.GetSubscribedFeaturesRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.GetSubscribedFeaturesResponse>;

  getSubscribedFeatures(
    request: services_central_partner_service_pb.GetSubscribedFeaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetSubscribedFeaturesResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.GetSubscribedFeaturesResponse>;

  getSubscribedFeatures(
    request: services_central_partner_service_pb.GetSubscribedFeaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.GetSubscribedFeaturesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/GetSubscribedFeatures',
        request,
        metadata || {},
        this.methodInfoGetSubscribedFeatures,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/GetSubscribedFeatures',
    request,
    metadata || {},
    this.methodInfoGetSubscribedFeatures);
  }

  methodInfoAddUpdateExternalUserContract = new grpcWeb.MethodDescriptor(
    '/services.central.PartnerService/AddUpdateExternalUserContract',
    grpcWeb.MethodType.UNARY,
    services_central_partner_service_pb.AddUpdateExternalUserContractRequest,
    services_central_partner_service_pb.AddUpdateExternalUserContractResponse,
    (request: services_central_partner_service_pb.AddUpdateExternalUserContractRequest) => {
      return request.serializeBinary();
    },
    services_central_partner_service_pb.AddUpdateExternalUserContractResponse.deserializeBinary
  );

  addUpdateExternalUserContract(
    request: services_central_partner_service_pb.AddUpdateExternalUserContractRequest,
    metadata: grpcWeb.Metadata | null): Promise<services_central_partner_service_pb.AddUpdateExternalUserContractResponse>;

  addUpdateExternalUserContract(
    request: services_central_partner_service_pb.AddUpdateExternalUserContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.AddUpdateExternalUserContractResponse) => void): grpcWeb.ClientReadableStream<services_central_partner_service_pb.AddUpdateExternalUserContractResponse>;

  addUpdateExternalUserContract(
    request: services_central_partner_service_pb.AddUpdateExternalUserContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: services_central_partner_service_pb.AddUpdateExternalUserContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/services.central.PartnerService/AddUpdateExternalUserContract',
        request,
        metadata || {},
        this.methodInfoAddUpdateExternalUserContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/services.central.PartnerService/AddUpdateExternalUserContract',
    request,
    metadata || {},
    this.methodInfoAddUpdateExternalUserContract);
  }

}

