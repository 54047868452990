import moment from "moment";

export default class IoUtils {
  static fetchFile(
    bytes: Uint8Array,
    type: FileType = FileType.JSON,
    name: string = moment(new Date()).format("MM-DD-YYYY")
  ) {
    const blob = new Blob([bytes], { type: "application/" + type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const fileName = name;
    link.download = fileName;
    link.click();
  }

  static async readFile(file: File) {
    return new Uint8Array(
      await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("loadend", (e) => resolve(e.target?.result as Iterable<number>));
        reader.addEventListener("error", reject);
        reader.readAsArrayBuffer(file);
      })
    );
  }
}

export enum FileType {
  PDF = "pdf",
  JSON = "json",
}
