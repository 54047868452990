
import { spaceServiceClient, tariffServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AddTariffRequest, UpdateTariffRequest } from "zaehlerfreunde-central/tariff_service_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { GasEnergyValue, Tariff } from "zaehlerfreunde-proto-types/tariff_pb";
import TariffAdvancePayments from "./TariffAdvancePayments.vue";
import { tariffModule } from "@/store/modules/tariff";
import {
  GetGasEnergyValueRequest,
  GetSpaceRequest,
  SetGasEnergyValueRequest,
} from "zaehlerfreunde-central/space_service_pb";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";

interface PriceComponentEdit {
  icon: string;
  name: string;
  type: Tariff.PriceComponent.Type;
  unit: Tariff.PriceComponent.Unit;
  unitDescription: string;
  value?: number;
  notEditable?: boolean;
  optional?: boolean;
  hint?: string;
}

@Component({
  components: {
    TariffAdvancePayments,
  },
})
export default class AddEditTariff extends Vue {
  @Prop() value: boolean;
  @Prop() medium: Device.Medium;
  @Prop({ default: "" }) spaceId: string;
  @Prop({ default: null }) existingTariff: Tariff | null;

  @tariffModule.State currentElectricityTariff: RemoteData<UserError, Tariff | null>;
  @featuresModules.Getter enabledFeatures: Set<Feature>;

  Medium = Device.Medium;

  name: string = "";
  supplier: string = "";
  isDynamicTariff: boolean = false;
  startDate: Date | null = null;
  earliestCancelDate: Date | null = null;
  newAdvancePayment: Tariff.AdvancePayment | null = null;
  yearlyConsumption: string = "";

  // Gas energy value
  energyValue: number = 0;
  correctionFactor: number = 0;

  saveTariffCall: RemoteCall<UserError> = initialized;

  priceComponents: PriceComponentEdit[] = [];

  requiredRule(input: string): string | boolean {
    return input ? true : "Dieses Feld ist erforderlich";
  }

  get spaceHasTariff(): boolean {
    return !!this.currentElectricityTariff.data;
  }

  get infoComplete(): boolean {
    return (
      (!!this.startDate || !this.spaceHasTariff) &&
      (!!this.yearlyConsumption || this.spaceHasTariff) &&
      ((!!this.energyValue && !!this.correctionFactor) || this.medium !== Device.Medium.GAS) &&
      !!this.supplier &&
      this.priceComponents.every((p) => p.optional || p.notEditable || p.value)
    );
  }

  get yearlyConsumptionNumber(): number {
    return parseFloat(this.yearlyConsumption);
  }

  get isAdvancePaymentShown(): boolean {
    return (
      this.enabledFeatures.has(Feature.TARIFF_ADVANCE_PAYMENTS) &&
      this.medium === Device.Medium.ELECTRICITY &&
      !this.isDynamicTariff
    );
  }

  mounted(): void {
    this.setPriceComponents();

    if (this.existingTariff) {
      this.name = this.existingTariff.getName();
      this.supplier = this.existingTariff.getSupplier();

      const startDate = this.existingTariff.getContract()?.getStartDate();
      const earliestCancelDate = this.existingTariff.getContract()?.getEarliestCancelDate();

      if (startDate) {
        this.startDate = new Date(startDate);
      }

      if (earliestCancelDate) {
        this.earliestCancelDate = new Date(earliestCancelDate);
      }
    }

    if (this.medium === Device.Medium.GAS) {
      this.getGasEnergyValue();
    }
  }

  async save(): Promise<void> {
    const tariff = new Tariff();
    tariff.setName(this.name);
    tariff.setSupplier(this.supplier);
    tariff.setMedium(this.medium);

    const contract = new Tariff.Contract();
    contract.setSpaceId(this.spaceId);

    if (this.existingTariff) {
      tariff.setId(this.existingTariff.getId());
      contract.setId(this.existingTariff.getContract()?.getId() ?? "");
    }

    if (this.startDate) {
      contract.setStartDate((this.startDate ?? new Date()).toDateString());
    }

    if (this.earliestCancelDate) {
      contract.setEarliestCancelDate((this.earliestCancelDate ?? new Date()).toDateString());
    }

    tariff.setContract(contract);

    const priceComponents = this.priceComponents
      .filter((pc) => !pc.optional || pc.value || pc.notEditable)
      .map((pc) => {
        const priceComponent = new Tariff.PriceComponent();
        priceComponent.setName(pc.name);
        priceComponent.setType(pc.type);
        priceComponent.setUnit(pc.unit);

        if (pc.value !== undefined) {
          priceComponent.setValue(pc.value);
        }

        return priceComponent;
      });

    tariff.setPriceComponentsList(priceComponents);

    try {
      this.saveTariffCall = pending;

      if (this.existingTariff) {
        await tariffServiceClient.updateTariff(
          new UpdateTariffRequest().setTariff(tariff).setAdvancePayment(this.newAdvancePayment ?? undefined),
          {}
        );
      } else {
        await tariffServiceClient.addTariff(
          new AddTariffRequest()
            .setTariff(tariff)
            .setAdvancePayment(this.newAdvancePayment ?? undefined)
            .setEstimatedYearlyConsumption(this.yearlyConsumptionNumber),
          {}
        );
      }

      if (this.medium === Device.Medium.GAS) {
        const request = new SetGasEnergyValueRequest();
        const energyValue = new GasEnergyValue();
        energyValue.setEnergyValue(this.energyValue);
        energyValue.setCorrectionFactor(this.correctionFactor);
        request.setEnergyValue(energyValue);
        request.setSpaceId(this.spaceId);

        await spaceServiceClient.setGasEnergyValue(request, {});
      }

      this.saveTariffCall = success(void 0);
      this.$emit("input", false);
      this.$emit("tariff-updated");
    } catch (error) {
      this.saveTariffCall = failure(userErrorFrom(error));
    }
  }

  async getGasEnergyValue(): Promise<void> {
    const response = await spaceServiceClient.getGasEnergyValue(
      new GetGasEnergyValueRequest().setSpaceId(this.spaceId),
      {}
    );

    this.energyValue = response.getEnergyValue()?.getEnergyValue() ?? 0;
    this.correctionFactor = response.getEnergyValue()?.getCorrectionFactor() ?? 0;
  }

  @Watch("isDynamicTariff")
  async setPriceComponents() {
    if (this.existingTariff) {
      this.priceComponents = this.existingTariff.getPriceComponentsList().map((pc) => ({
        icon: pc.getName(),
        name: pc.getName(),
        type: pc.getType(),
        unit: pc.getUnit(),
        unitDescription: pc.getUnitDescription(),
        optional: pc.getValue() === 0,
        value: pc.getValue(),
        notEditable: pc.getType() === Tariff.PriceComponent.Type.EPEX_SPOT_PRICE,
      }));
    } else {
      const priceComponents: PriceComponentEdit[] = [
        {
          icon: "mdi-calendar-refresh",
          type: Tariff.PriceComponent.Type.STATIC_PRICE,
          name: "Grundpreis",
          unit: Tariff.PriceComponent.Unit.EURO_PER_MONTH,
          unitDescription: "€/Monat",
        },
      ];

      if (this.isDynamicTariff) {
        priceComponents.push(
          {
            icon: "mdi-account",
            name: "EPEX Spot Preis",
            type: Tariff.PriceComponent.Type.EPEX_SPOT_PRICE,
            unit: Tariff.PriceComponent.Unit.CENT_PER_KILO_WATT_HOUR,
            unitDescription: "Cent/kWh",
            notEditable: true,
          },
          {
            icon: "mdi-counter",
            name: "Stromnebenkosten",
            type: Tariff.PriceComponent.Type.STATIC_PRICE,
            unit: Tariff.PriceComponent.Unit.CENT_PER_KILO_WATT_HOUR,
            unitDescription: "Cent/kWh",
          },
          {
            icon: "mdi-arrow-collapse-up",
            name: "Maximalpreis",
            type: Tariff.PriceComponent.Type.MAX_PRICE,
            unit: Tariff.PriceComponent.Unit.CENT_PER_KILO_WATT_HOUR,
            unitDescription: "Cent/kWh",
            optional: true,
          },
          {
            icon: "mdi-arrow-collapse-down",
            name: "Minimalpreis",
            type: Tariff.PriceComponent.Type.MIN_PRICE,
            unit: Tariff.PriceComponent.Unit.CENT_PER_KILO_WATT_HOUR,
            unitDescription: "Cent/kWh",
            optional: true,
          }
        );
      } else {
        var hint: string | undefined = undefined;

        try {
          const request = new GetSpaceRequest();
          request.setSpaceId(this.spaceId);
          const response = await spaceServiceClient.getSpace(request, {});
          if (response.getSpace()?.getAddress()?.getCountry()?.getId() === "AUT") {
            hint = "inklusive Netznutzungsentgelt, Steuern und Abgaben";
          }
        } catch (error) {
          console.error(error);
        }

        priceComponents.push({
          icon: "mdi-counter",
          name: "Arbeitspreis",
          hint,
          type: Tariff.PriceComponent.Type.STATIC_PRICE,
          unit:
            this.medium === Device.Medium.WATER
              ? Tariff.PriceComponent.Unit.EURO_PER_CUBIC_METER
              : Tariff.PriceComponent.Unit.CENT_PER_KILO_WATT_HOUR,
          unitDescription: this.medium === Device.Medium.WATER ? "€/㎥" : "Cent/kWh",
        });
      }

      if (this.medium === Device.Medium.ELECTRICITY || this.medium === Device.Medium.GAS) {
        priceComponents.push({
          icon: "mdi-molecule-co2",
          name: "CO2 Emissionen",
          type: Tariff.PriceComponent.Type.CO2_EMISSIONS,
          unit: Tariff.PriceComponent.Unit.KG_CO2_PER_KILO_WATT_HOUR,
          unitDescription: "kg/kWh",
          optional: true,
        });
      }

      this.priceComponents = priceComponents;
    }
  }
}
