
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CompositeChart from "@/components/charts/CompositeChart.vue";
import { TimePeriod, TimePeriodChangeEvent } from "@/components/charts/TimePeriodPicker.vue";
import { RemoteData, failure, initialized, pending, successOptional } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import moment from "moment";
import { ChartData, GetDashboardChartDataRequest } from "zaehlerfreunde-central/ui_service_pb";
import { subscriptionModule } from "@/store/modules/subscription";
import { uiServiceClient } from "@/config/service-clients";
import spaces from "@/store/modules/spaces";
import { dateToDateTime } from "@/utils/proto-utils";
import { devicesModule } from "@/store/modules/devices";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { partnerModule } from "@/store/modules/partner";

@Component({
  components: {
    CompositeChart,
  },
})
export default class ConsumptionGraph extends Vue {
  @subscriptionModule.Getter includedFeatures: Feature[];
  @Prop({ default: null }) deviceId: string | null;
  @Prop({ default: false }) isAdminView: boolean;
  @devicesModule.State devices: RemoteData<UserError, Device[]>;
  @partnerModule.Getter isPartnerVoltego: boolean;

  selectedDeviceId: string = "";
  isDefaultTimePeriod: boolean = true;
  dashboardChartData: RemoteData<UserError, ChartData> = initialized;
  selectedTimePeriod = TimePeriod.DAY;
  selectedTab = ChartData.Tab.Type.CONSUMPTION;
  startDate = moment().startOf(this.selectedTimePeriod.timeRange).toDate();
  endDate = moment().endOf(this.selectedTimePeriod.timeRange).toDate();

  @Watch("selectedDeviceId")
  onSelectedDeviceIdChanged(): void {
    if (this.isNonSmartMeterProvider(this.selectedDeviceId)) {
      this.selectedTimePeriod = TimePeriod.YEAR;
      this.startDate = moment().startOf(this.selectedTimePeriod.timeRange).toDate();
      this.endDate = moment().endOf(this.selectedTimePeriod.timeRange).toDate();
    }
    this.loadChartData();
  }

  mounted(): void {
    if (this.isPartnerVoltego) {
      this.selectedTab = ChartData.Tab.Type.MARKET_DATA;
    }

    if (this.deviceId) {
      this.selectedDeviceId = this.deviceId;
    }
  }

  isNonSmartMeterProvider(deviceId: string) {
    const device = this.devices.list.find((d) => d.getId() == deviceId);
    return device?.getProvider() == Device.Provider.NON_SMART_METER_PROVIDER;
  }

  async loadChartData(): Promise<void> {
    if (this.selectedDeviceId.length > 0) {
      const selectedDeviceId = this.selectedDeviceId;
      const selectedStartDate = this.startDate;
      const selectedEndDate = this.endDate;
      const selectedTab = this.selectedTab;

      const request = new GetDashboardChartDataRequest();

      request.setStartDate(dateToDateTime(this.startDate));
      request.setEndDate(dateToDateTime(this.endDate));
      request.setDeviceId(this.selectedDeviceId);
      if (!this.isAdminView) {
        request.setSpaceId(spaces.selectedSpaceId);
      }

      if (selectedTab !== null) {
        request.setTab(selectedTab);
      }

      request.setForceTimeWindow(!this.isDefaultTimePeriod);

      this.dashboardChartData = pending;

      try {
        const response = await uiServiceClient.getDashboardChartData(request, {});
        const chartData = response.getChartData();
        if (
          this.selectedDeviceId === selectedDeviceId &&
          this.startDate === selectedStartDate &&
          this.endDate === selectedEndDate &&
          this.selectedTab === selectedTab
        ) {
          if (chartData) {
            this.dashboardChartData = successOptional(chartData);
            if (chartData.getIsDifferentDateThanRequested()) {
              this.startDate.setDate(this.startDate.getDate() - 1);
              this.endDate.setDate(this.endDate.getDate() - 1);
            }
          }
        }
      } catch (error) {
        if (
          this.selectedDeviceId === selectedDeviceId &&
          this.startDate === selectedStartDate &&
          this.endDate === selectedEndDate &&
          this.selectedTab === selectedTab
        ) {
          this.dashboardChartData = failure(userErrorFrom(error));
        }
      }
    }
  }

  onTimePeriodChanged(event: TimePeriodChangeEvent): void {
    this.selectedTimePeriod = event.timePeriod;
    this.startDate = event.start;
    this.endDate = event.end;
    this.isDefaultTimePeriod = false;
    this.loadChartData();
  }

  onTabSelected(tab: ChartData.Tab.Type): void {
    this.selectedTab = tab;
    this.loadChartData();
  }
}
