
import { Vue, Component, Prop } from "vue-property-decorator";

export interface PaymentDetailsInput {
  accountHolder: string;
  iban: string;
  bic: string;
  sepaConfirmation: boolean;
  payPerInvoice: boolean;
}

@Component
export default class PaymentDetails extends Vue {
  accountHolder = "";
  iban = "";
  bic = "";
  sepaConfirmation = false;
  payPerInvoice = false;

  @Prop() value: PaymentDetailsInput | null;

  updated() {
    if (this.payPerInvoice || (this.sepaConfirmation && this.accountHolder && this.iban && this.bic)) {
      this.$emit("input", {
        accountHolder: this.accountHolder,
        iban: this.iban,
        bic: this.bic,
        sepaConfirmation: this.sepaConfirmation,
        payPerInvoice: this.payPerInvoice,
      });
    } else {
      this.$emit("input", null);
    }
  }

  mounted() {
    if (this.value) {
      this.accountHolder = this.value.accountHolder;
      this.iban = this.value.iban;
      this.bic = this.value.bic;
      this.sepaConfirmation = this.value.sepaConfirmation;
      this.payPerInvoice = this.value.payPerInvoice;
    }
  }
}
