
import { Vue, Component, Watch, Prop } from "vue-property-decorator";

export interface CustomerTypeInput {
  postcode: string;
  customerType: "residential" | "commercial";
  yearlyConsumption: string;
}

@Component
export default class ChooseCustomerType extends Vue {
  tab = 0;

  postcode = "";
  yearlyConsumption = "1500";
  householdSize = 1;

  @Prop() value: CustomerTypeInput | null;

  @Watch("yearlyConsumption")
  @Watch("tab")
  @Watch("postcode")
  onValueChanged() {
    if (this.postcode && this.yearlyConsumption) {
      this.$emit("input", {
        customerType: this.tab === 0 ? "residential" : "commercial",
        yearlyConsumption: this.yearlyConsumption,
        postcode: this.postcode,
      });
    }
  }

  @Watch("householdSize")
  onHouseholdSizeChanged() {
    switch (this.householdSize) {
      case 1:
        this.yearlyConsumption = "1500";
        break;
      case 2:
        this.yearlyConsumption = "2500";
        break;
      case 3:
        this.yearlyConsumption = "3500";
        break;
      case 4:
        this.yearlyConsumption = "4250";
        break;
    }
  }

  mounted() {
    if (this.value) {
      this.yearlyConsumption = this.value.yearlyConsumption;
      this.postcode = this.value.postcode;
      this.tab = this.value.customerType === "residential" ? 0 : 1;
    }
  }
}
