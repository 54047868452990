
import { RemoteCall, RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { User } from "zaehlerfreunde-proto-types/user_pb";
import { DeleteAccountRequest, GetUserByIdRequest } from "zaehlerfreunde-central/user_service_pb";
import { userServiceClient } from "@/config/service-clients";
import UserDetails from "./UserDetails.vue";
import UserSpaces from "./UserSpaces.vue";
import UserDevices from "./UserDevices.vue";
import { paths } from "@/router/routes";
import { adminModule } from "@/store/modules/admin";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import PartnerSelect from "@/pages/platform/Partner/PartnerSelect.vue";
@Component({
  components: {
    UserDetails,
    UserSpaces,
    UserDevices,
    PartnerSelect,
  },
})
export default class UserProfile extends Vue {
  selectedTab: number = 1;
  userInfo: RemoteData<UserError, User> = initialized;
  userId: string = "";
  key: number = 0;
  paths = paths;
  partnerId: string = "";
  @adminModule.State isSuperAdminUser: RemoteData<UserError, boolean>;
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  deleteUserCall: RemoteCall<UserError> = initialized;

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    this.back();
  }

  async mounted(): Promise<void> {
    this.userInfo = pending;
    this.userId = this.$route.params.userId;
    const request = new GetUserByIdRequest();
    request.setUserId(this.userId);

    try {
      const response = await userServiceClient.getUserById(request, {});
      const userData = response.getUser();
      if (userData) {
        this.userInfo = success(userData);
        this.partnerId = userData.getPartnerId();
      }
    } catch (error) {
      this.userInfo = failure(userErrorFrom(error));
    }
  }

  async deleteUser() {
    try {
      this.deleteUserCall = pending;

      const request = new DeleteAccountRequest();
      request.setUserId(this.userId);

      await userServiceClient.deleteAccount(request, {});

      this.$router.go(-1);
      this.deleteUserCall = success(void 0);
    } catch (error) {
      this.deleteUserCall = failure(userErrorFrom(error));
    }
  }

  back() {
    this.$router.go(-1);
  }

  get isSuperAdmin(): boolean {
    return this.isSuperAdminUser.data?.valueOf() ?? false;
  }

  changeTab(number: number) {
    this.selectedTab = number;
  }
}
