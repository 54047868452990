
import { pending, failure, success, initialized, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import {
  DeviceRegistration,
  MetiundoLoginDetails,
  MetiundoMeteringPoint,
  SingleMetiundoRegistrationDetails,
} from "zaehlerfreunde-proto-types/device_registration_pb";
import spaces from "@/store/modules/spaces";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { GetMetiundoMeteringPointsRequest } from "zaehlerfreunde-central/device_service_pb";
import { RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";

@Component
export default class MetiundoConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registerDeviceCall: RemoteCall<UserError> = initialized;
  email: string = "";
  password: string = "";
  selectedDevice: string = "";
  acceptedTerms: boolean = false;

  meteringPoints: RemoteData<UserError, MetiundoMeteringPoint[]> = initialized;

  deviceName(device: MetiundoMeteringPoint): string {
    return device.getName();
  }

  deviceValue(device: MetiundoMeteringPoint): string {
    return device.getId();
  }

  deviceLocation(device: MetiundoMeteringPoint): string {
    return device.getAddress();
  }

  async loadMeteringPoints(): Promise<void> {
    try {
      this.meteringPoints = pending;

      const request = new GetMetiundoMeteringPointsRequest();
      request.setEmail(this.email);
      request.setPassword(this.password);

      const response = await deviceServiceClient.getMetiundoMeteringPoints(request, {});
      const meteringPoints = response.getMeteringPointsList();
      this.meteringPoints = success(meteringPoints);
    } catch (error) {
      this.meteringPoints = failure(userErrorFrom(error));
    }
  }

  back(): void {
    this.$router.go(-1);
  }

  async onRegisterClicked(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice ?? "false");
    registration.setSpaceMapping(spaceMapping);

    const loginDetails = new MetiundoLoginDetails();
    loginDetails.setEmail(this.email);
    loginDetails.setPassword(this.password);

    const details = new SingleMetiundoRegistrationDetails();
    details.setLoginDetails(loginDetails);
    details.setDeviceId(this.selectedDevice);

    registration.setSingleMetiundo(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);
    this.registerDeviceCall = pending;

    try {
      await deviceServiceClient.registerDevice(request, {});
      this.registerDeviceCall = success(void 0);
      this.$router.go(-1);
    } catch (error) {
      this.registerDeviceCall = failure(userErrorFrom(error));
    }
  }
}
