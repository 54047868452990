
import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerInfoInput } from "./CustomerInfo.vue";
import { SiteInput } from "../SelectSite.vue";

export interface PreviousContractInput {
  sameBillingAddress: boolean;
  name: string;
  street: string;
  postcode: string;
  city: string;
  orderReason: string;
  previousSupplierContract: string;
  previousSupplier: string;
  previousCustomerId: string;
  meterReading: string;
  lastYearConsumption: string;
  desiredStartDate: string;
}

@Component
export default class PreviousContract extends Vue {
  sameBillingAddress = true;
  name = "";
  street = "";
  postcode = "";
  city = "";
  orderReason: string | null = null;
  previousSupplierContract: string | null = null;
  previousSupplier = "";
  previousCustomerId = "";
  meterReading = "";
  lastYearConsumption = "";
  desiredStartDate: Date | null = null;

  @Prop() customerInfoInput: CustomerInfoInput;
  @Prop() solarizeSiteInput: SiteInput;
  @Prop() value: PreviousContractInput | null;

  updated() {
    if (
      (this.sameBillingAddress || (this.street && this.postcode && this.city && this.name)) &&
      this.orderReason &&
      this.desiredStartDate &&
      (this.orderReason !== "supplier-changed" ||
        (this.previousSupplier &&
          this.previousCustomerId &&
          this.meterReading &&
          this.lastYearConsumption &&
          this.previousSupplierContract))
    ) {
      this.$emit("input", {
        sameBillingAddress: this.sameBillingAddress,
        name: this.name,
        street: this.street,
        postcode: this.postcode,
        city: this.city,
        orderReason: this.orderReason,
        previousSupplierContract: this.previousSupplierContract,
        previousSupplier: this.previousSupplier,
        previousCustomerId: this.previousCustomerId,
        meterReading: this.meterReading,
        lastYearConsumption: this.lastYearConsumption,
        desiredStartDate: this.desiredStartDate?.toDateString(),
      } as PreviousContractInput);
    } else {
      this.$emit("input", null);
    }
  }

  mounted() {
    if (this.value) {
      this.sameBillingAddress = this.value.sameBillingAddress;
      this.name = this.value.name;
      this.street = this.value.street;
      this.postcode = this.value.postcode;
      this.city = this.value.city;
      this.orderReason = this.value.orderReason;
      this.previousSupplierContract = this.value.previousSupplierContract;
      this.previousSupplier = this.value.previousSupplier;
      this.previousCustomerId = this.value.previousCustomerId;
      this.meterReading = this.value.meterReading;
      this.lastYearConsumption = this.value.lastYearConsumption;
      this.desiredStartDate = this.value.desiredStartDate ? new Date(this.value.desiredStartDate) : null;
    }
  }
}
