
import { Component, Prop, Vue } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import spaces from "@/store/modules/spaces";
import AddEditTariff from "../space-settings/tariff/AddEditTariff.vue";
import { subscriptionModule } from "@/store/modules/subscription";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { paths } from "@/router/routes";

@Component({
  components: {
    AddEditTariff,
  },
})
export default class AddTariffReminder extends Vue {
  @Prop({ default: false }) reason: "tariff-switch" | "monthly-costs" | "simulator" | "another-tariff" | "first-tariff";
  @Prop({ default: Device.Medium.ELECTRICITY }) deviceMedium: Device.Medium;
  @Prop({ default: "" }) spaceId: string;

  zfProtoDeviceMedium = Device.Medium;
  addUpdateTariffDialog = false;
  @subscriptionModule.Getter includedFeatures: Feature[];

  get selectedSpaceId(): string {
    if (this.spaceId) {
      return this.spaceId;
    } else {
      return spaces.selectedSpace?.getId() ?? "";
    }
  }

  get currentMonth(): string {
    return new Date().toLocaleString("de-DE", { month: "long" });
  }

  get requiredFeature(): Feature | null {
    switch (this.deviceMedium) {
      case Device.Medium.ELECTRICITY:
        return Feature.ENERGY_TARIFF;
      case Device.Medium.GAS:
        return Feature.GAS_TARIFF;
      case Device.Medium.WATER:
        return Feature.WATER_TARIFF;
      default:
        return null;
    }
  }

  showAddUpdateTariffDialog() {
    if (this.requiredFeature !== null && !this.includedFeatures.includes(this.requiredFeature)) {
      this.$router.push({
        path: paths.platform.account.subscriptions,
        query: { requiredFeature: "tariff" },
      });
    } else {
      this.addUpdateTariffDialog = true;
    }
  }
}
