
import devices, { devicesModule } from "@/store/modules/devices";
import spaces, { spacesModule } from "@/store/modules/spaces";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import DeleteDevice from "./DeleteDevice.vue";
import CsvExport from "@/components/CSVExport.vue";
import { subscriptionModule } from "@/store/modules/subscription";
import { getMediumColor, getMediumIcon, getMediumName } from "@/utils/device-utils";
import { paths } from "@/router/routes";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import DeviceSettings from "./DeviceSettings.vue";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { AccessPermission } from "zaehlerfreunde-proto-types/permissions_pb";

@Component({
  components: {
    DeviceSettings,
    DeleteDevice,
    CsvExport,
  },
})
export default class Devices extends Vue {
  @devicesModule.Getter devicesWithoutChildSpaces: RemoteData<UserError, Device[]>;
  @spacesModule.State selectedSpace: Space | null;
  @spacesModule.Getter selectedSpaceName: string;
  @subscriptionModule.Getter includedFeatures: Feature[];
  @partnerModule.State partner: RemoteData<UserError, Partner>;
  @partnerModule.Getter supportEmail: string;
  @spacesModule.Getter accessPermissions: AccessPermission[];

  DeviceStatus = Device.Status;
  DeviceMedium = Device.Medium;
  getMediumIcon = getMediumIcon;
  getMediumName = getMediumName;
  getMediumColor = getMediumColor;

  deviceToDelete: Device | null = null;
  displayDetailsOfDevice: Device | null = null;
  showCsvExportDialog: boolean = false;

  metaInfo(): { title: string } {
    return {
      title: "Geräte",
    };
  }

  @Watch("selectedSpace")
  onSelectedSpaceChanged(): void {
    this.loadData();
  }

  mounted(): void {
    this.loadData();
  }

  loadData(): void {
    if (this.selectedSpace) {
      devices.getDevices();
      spaces.getAccessPermissions();
    }
  }

  get userAllowedToEdit(): boolean {
    return this.accessPermissions.some((a) => a === AccessPermission.EDIT);
  }

  onConnectDeviceClicked(): void {
    if (
      (this.devicesWithoutChildSpaces.list.length === 0 && this.includedFeatures.includes(Feature.ONE_DEVICE)) ||
      this.includedFeatures.includes(Feature.UNLIMITED_DEVICES)
    ) {
      this.$router.push(paths.platform.connectDevice);
    } else {
      this.$router.push({ path: paths.platform.account.subscriptions, query: { requiredFeature: "multiple-devices" } });
    }
  }

  onDeleteDeviceClicked(event: CustomEvent, device: Device): void {
    event.stopPropagation();
    this.deviceToDelete = device;
  }

  onCsvExportClicked(): void {
    if (this.includedFeatures.includes(Feature.CSV_EXPORT)) {
      this.$router.push({ path: paths.platform.account.subscriptions, query: { requiredFeature: "csv-export" } });
    } else {
      this.showCsvExportDialog = true;
    }
  }
}
