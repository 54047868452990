
import { failure, success, initialized, RemoteCall, RemoteData, pending } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { deviceServiceClient } from "@/config/service-clients";
import { Vue, Component, Prop } from "vue-property-decorator";
import { GeneralDeviceInfo } from "../general/GeneralSteps.vue";
import { GetIometerQRCodeRequest, RegisterDeviceRequest } from "zaehlerfreunde-central/device_service_pb";
import { DeviceRegistration, IoMeterRegistrationDetails } from "zaehlerfreunde-proto-types/device_registration_pb";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import spaces from "@/store/modules/spaces";

@Component({
  components: {},
})
export default class IometerConnection extends Vue {
  @Prop() generalInfo: GeneralDeviceInfo;
  @Prop({ default: false }) adminAction: boolean;

  registerDeviceCall: RemoteCall<UserError> = initialized;
  qrCode: RemoteData<UserError, string> = initialized;
  deviceId: string | null = null;
  showInfo: boolean = true;
  get explanation(): string {
    return "Bitte scannen Sie diesen QR Code mit ihrer IOmeter App um das Gerät zu verbinden. Falls Sie die App schon installiert hatten, löschen Sie diese und downloaden Sie die App erneut um den QR-Code scannen zu können. Nachdem Sie das Gerät konfiguriert haben, drücken Sie unten bitte auf “Gerät Verbinden”.";
  }

  get info(): string {
    return "Bitte fügen Sie dieses Gerät über ihr Desktop/Laptop hinzu, da Sie einen QR-Code scannen müssen.";
  }

  mounted(): void {
    this.getQRCode();
  }

  async getQRCode(): Promise<void> {
    const request = new GetIometerQRCodeRequest();

    try {
      this.qrCode = pending;
      const response = await deviceServiceClient.getIometerQRCode(request, {});
      this.qrCode = success(response.getQrCode());
      this.deviceId = response.getDeviceId();
    } catch (error) {
      this.qrCode = failure(userErrorFrom(error));
    }
  }

  async registerDevice(): Promise<void> {
    const registration = new DeviceRegistration();
    registration.setDeviceName(this.generalInfo.deviceName);
    registration.setDeviceMedium(Device.Medium.ELECTRICITY);
    registration.setDeviceType(this.generalInfo.deviceType);
    registration.setDeviceProvider(this.generalInfo.deviceProvider);

    const spaceMapping = new DeviceRegistration.SpaceMapping();
    spaceMapping.setSpaceId(this.adminAction ? spaces.adminSelectedSpaceId : spaces.selectedSpaceId);
    spaceMapping.setIsMainDevice(this.generalInfo.isMainDevice ?? "false");
    registration.setSpaceMapping(spaceMapping);

    const details = new IoMeterRegistrationDetails();
    if (this.deviceId && this.qrCode.data) {
      details.setDeviceId(this.deviceId);
      details.setIometerQrCode(this.qrCode.data);
    }

    registration.setIometer(details);

    const request = new RegisterDeviceRequest();
    request.setRegistration(registration);
    this.registerDeviceCall = pending;

    try {
      await deviceServiceClient.registerDevice(request, {});
      this.registerDeviceCall = success(void 0);
      this.$router.go(-1);
    } catch (error) {
      this.registerDeviceCall = failure(userErrorFrom(error));
    }
  }
}
