
import { deviceServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { formatNumber } from "@/utils/number-utils";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { GetSolarizeProductsRequest, GetSolarizeSitesRequest } from "zaehlerfreunde-central/device_service_pb";
import { SolarizePrice } from "zaehlerfreunde-proto-types/device_registration_pb";

export interface ConfirmTariffDetailsInput {
  readDocuments: boolean;
  productNotifications: boolean;
  offerNotifications: boolean;
}

@Component
export default class ConfirmTariffDetails extends Vue {
  readDocuments = false;
  productNotifications = false;
  offerNotifications = false;

  formatNumber = formatNumber;

  prices: RemoteData<UserError, SolarizePrice[]> = initialized;

  @Prop() siteId: string;
  @Prop() value: ConfirmTariffDetailsInput | null;

  @Watch("readDocuments")
  @Watch("productNotifications")
  @Watch("offerNotifications")
  onChange() {
    if (this.readDocuments && this.productNotifications) {
      this.$emit("input", {
        readDocuments: this.readDocuments,
        productNotifications: this.productNotifications,
        offerNotifications: this.offerNotifications,
      });
    } else {
      this.$emit("input", null);
    }
  }

  mounted() {
    if (this.value) {
      this.readDocuments = this.value.readDocuments;
      this.productNotifications = this.value.productNotifications;
      this.offerNotifications = this.value.offerNotifications;
    }

    this.getSolarizePrices();
  }

  async getSolarizePrices() {
    try {
      this.prices = pending;

      const request = new GetSolarizeProductsRequest();
      request.setPartnerId("ip");
      request.setSiteId(this.siteId);

      const response = await deviceServiceClient.getSolarizeProducts(request, {});

      if (response.getProductsList().length > 0) {
        this.prices = success(response.getProductsList()[0].getPricesList());
      }
    } catch (error) {
      this.prices = failure(userErrorFrom(error));
    }
  }
}
