
import { Vue, Component, Prop } from "vue-property-decorator";

export interface CustomerInfoInput {
  salutation?: string;
  title?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  street: string;
  postcode: string;
  city: string;
  lastYearConsumption?: string;
  meterReading?: string;
}

@Component
export default class CustomerInfo extends Vue {
  salutation = "";
  title = "";
  firstName = "";
  lastName = "";
  email = "";
  phoneNo = "";
  street = "";
  postcode = "";
  city = "";
  lastYearConsumption = "";
  meterReading = "";

  @Prop() value: CustomerInfoInput | null;

  updated() {
    if (this.firstName && this.lastName && this.email && this.phoneNo && this.street && this.postcode && this.city) {
      this.$emit("input", {
        salutation: this.salutation ?? undefined,
        title: this.title ?? undefined,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNo: this.phoneNo,
        street: this.street,
        postcode: this.postcode,
        city: this.city,
        lastYearConsumption: this.lastYearConsumption ?? undefined,
        meterReading: this.meterReading ?? undefined,
      });
    } else {
      this.$emit("input", null);
    }
  }

  mounted() {
    if (this.value) {
      this.salutation = this.value.salutation ?? "";
      this.title = this.value.title ?? "";
      this.firstName = this.value.firstName;
      this.lastName = this.value.lastName;
      this.email = this.value.email;
      this.phoneNo = this.value.phoneNo;
      this.street = this.value.street;
      this.postcode = this.value.postcode;
      this.city = this.value.city;
      this.lastYearConsumption = this.value.lastYearConsumption ?? "";
      this.meterReading = this.value.meterReading ?? "";
    }
  }
}
