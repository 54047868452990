
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { unknownError, UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Device, HealthData, MetaDataKind } from "zaehlerfreunde-proto-types/device_pb";

import { deviceServiceClient, readingsServiceClient, spaceServiceClient } from "@/config/service-clients";
import spaces, { spacesModule } from "@/store/modules/spaces";
import { TableEntry } from "@/components/core/Table.vue";
import devices from "@/store/modules/devices";
import {
  DeviceDetails,
  GetHealthDataRequest,
  GetShellyDeviceDetailsRequest,
  GetVirtualDeviceRequest,
  GetVirtualDeviceResponse,
  ShellyDevice,
  UpdateDeviceDetailsRequest,
  UpdateShellyDeviceChannelDirectionsRequest,
} from "zaehlerfreunde-central/device_service_pb";
import { Direction } from "zaehlerfreunde-proto-types/device_reading_pb";
import { paths } from "@/router/routes";
import { GetBatteryCapacityRequest, SetBatteryCapacityRequest } from "zaehlerfreunde-central/reading_service_pb";
import { formatNumber, parseFloatOptional } from "@/utils/number-utils";
import { AccessPermission } from "zaehlerfreunde-proto-types/permissions_pb";
import DeviceHealth from "./DeviceHealth.vue";
@Component({
  components: {
    DeviceHealth,
  },
})
export default class DeviceSettings extends Vue {
  @Prop() device: Device | null;

  @spacesModule.Getter selectedSpaceId: string;
  @spacesModule.Getter accessPermissions: AccessPermission[];
  Device = Device;
  showDialog: boolean = false;
  isEdited: boolean = false;
  isMainDevice: boolean = false;
  deviceName: string = "";
  shellyDeviceDetails: RemoteData<UserError, ShellyDevice> = initialized;
  updateDeviceDetailsCall: RemoteCall<UserError> = initialized;
  updateChannelDirectionsCall: RemoteCall<UserError> = initialized;
  virtualDeviceDetails: RemoteData<UserError, GetVirtualDeviceResponse> = initialized;
  batteryCapacity: string = "";
  setBatteryCapacityCall: RemoteCall<UserError> = initialized;
  healthData: RemoteData<UserError, HealthData[]> = initialized;

  bitShakeIP: string | null = null;
  DeviceProvider = Device.Provider;
  iometerQRCode: string | null = null;

  copied: boolean = false;
  ocppMetaDatakind = MetaDataKind.OCPP_WEBSOCKET_URL;

  directionAnswers: { text: string; value: Direction }[] = [
    { text: "Einspeisung/Produktion", value: Direction.OUT },
    { text: "Verbrauch", value: Direction.IN },
  ];

  nonSmartMeterType = Device.Type.NON_SMART_METER;
  virtualMeterType = Device.Type.VIRTUAL_METER;

  get userAllowedToEdit(): boolean {
    return this.accessPermissions.some((a) => a === AccessPermission.EDIT);
  }

  get metaData(): TableEntry[][] | undefined {
    this.device?.getMetaDataList().forEach((data) => {
      if (data.getLabel() == "IP Addresse") {
        this.bitShakeIP = data.getValue();
      } else if (data.getLabel() == "IOmeter QR Code") {
        this.iometerQRCode = data.getValue();
      }
    });
    return this.device?.getMetaDataList()?.map((e) => [{ value: e.getLabel(), bold: true }, { value: e.getValue() }]);
  }

  get iometerExplanation(): string {
    return "Falls die Verbindung fehlergeschlagen ist, scannen Sie diesen QR Code mit ihrer IOmeter App nochmlas um das Gerät zu verbinden. Falls Sie die App schon installiert hatten, löschen Sie diese und downloaden Sie die App erneut um den QR-Code scannen zu können.";
  }

  get ipAddress(): string {
    return `http://${this.bitShakeIP}`;
  }

  get isDeviceWithChannelsDirections(): boolean {
    return (
      (this.device?.getProvider() === Device.Provider.SHELLY &&
        this.shellyDeviceDetails.data?.getIsDirectionsEditable()) ??
      false
    );
  }

  get showOcppConnectionReminder(): boolean {
    return this.device?.getStatus() !== Device.Status.CONNECTED && this.device?.getProvider() === Device.Provider.OCPP;
  }

  get showBatteryCapacity(): boolean {
    return this.device?.getProvider() === Device.Provider.OCPP;
  }

  get devicesOfVirtualDevice(): TableEntry[][] | undefined {
    return this.virtualDeviceDetails?.data
      ?.getDevicesList()
      .map((device) => [
        { value: device.getName(), bold: true },
        { value: device.getDeviceTypeInfo()?.getName() ?? "" },
      ]);
  }

  @Watch("device")
  onDeviceChanged(): void {
    this.showDialog = !!this.device;
    this.deviceName = this.device?.getName() ?? "";
    this.isMainDevice = this.device?.getIsMainDevice() ?? false;
    this.copied = false;
    if (this.device?.getProvider() == Device.Provider.SHELLY) {
      this.loadShellyDeviceDetails();
    }
    if (this.device?.getProvider() == Device.Provider.VIRTUAL) {
      this.loadVirtualDevice();
    }
  }

  @Watch("showDialog")
  onShowDialogChanged(): void {
    if (!this.showDialog) {
      this.$emit("closed");
    }
  }

  onCancelClicked(): void {
    this.isEdited = false;
    this.deviceName = this.device?.getName() ?? "";
    this.isMainDevice = this.device?.getIsMainDevice() ?? false;
  }

  @Watch("device")
  async getBatteryCapacity(): Promise<void> {
    if (!this.showBatteryCapacity) {
      return;
    }

    try {
      const request = new GetBatteryCapacityRequest();
      request.setDeviceId(this.device?.getId() ?? "");
      const response = await readingsServiceClient.getBatteryCapacity(request, {});
      this.batteryCapacity = formatNumber(response.getBatteryCapacity());
    } catch (error) {
      console.error(error);
    }
  }

  @Watch("device")
  async getHealthData(): Promise<void> {
    this.healthData = pending;

    try {
      const request = new GetHealthDataRequest();
      request.setDeviceId(this.device?.getId() ?? "");
      const response = await deviceServiceClient.getHealthData(request, {});
      this.healthData = success(response.getHealthDataList());
    } catch (error) {
      this.healthData = failure(userErrorFrom(error));
    }
  }

  async setBatteryCapacity(): Promise<void> {
    const request = new SetBatteryCapacityRequest();
    request.setDeviceId(this.device?.getId() ?? "");
    request.setBatteryCapacity(parseFloatOptional(this.batteryCapacity) ?? 0);

    try {
      this.setBatteryCapacityCall = pending;
      await readingsServiceClient.setBatteryCapacity(request, {});
      this.setBatteryCapacityCall = success(void 0);
    } catch (error) {
      this.setBatteryCapacityCall = failure(userErrorFrom(error));
    }
  }

  async loadShellyDeviceDetails(): Promise<void> {
    try {
      const request = new GetShellyDeviceDetailsRequest();
      request.setDeviceId(this.device?.getId() ?? "");
      const response = await deviceServiceClient.getShellyDeviceDetails(request, {});
      const shellyDeviceDetails = response.getShellyDevice();
      if (shellyDeviceDetails) {
        this.shellyDeviceDetails = success(shellyDeviceDetails);
      } else {
        this.shellyDeviceDetails = failure(unknownError);
      }
    } catch (error) {
      this.shellyDeviceDetails = failure(userErrorFrom(error));
    }
  }

  async loadVirtualDevice(): Promise<void> {
    try {
      const request = new GetVirtualDeviceRequest();
      request.setDeviceId(this.device?.getId() ?? "");
      request.setSpaceId(this.selectedSpaceId);
      const response = await deviceServiceClient.getVirtualDevice(request, {});
      this.virtualDeviceDetails = success(response);
    } catch (error) {
      this.virtualDeviceDetails = failure(userErrorFrom(error));
    }
  }

  updateDetails(): void {
    this.updateDevice();
    if (this.device?.getProvider() == Device.Provider.SHELLY) {
      this.updateChannelDirections();
    }

    this.isEdited = false;
  }

  async updateChannelDirections(): Promise<void> {
    try {
      this.updateChannelDirectionsCall = pending;
      const request = new UpdateShellyDeviceChannelDirectionsRequest();
      request.setShellyDeviceId(this.shellyDeviceDetails.data?.getDeviceId() ?? "");
      request.setChannnelDirectionsList(this.shellyDeviceDetails.data?.getChannelDirectionsList() ?? []);
      await deviceServiceClient.updateShellyDeviceChannelDirections(request, {});
      this.updateChannelDirectionsCall = success(void 0);
    } catch (error) {
      this.updateChannelDirectionsCall = failure(userErrorFrom(error));
    }
  }

  async updateDevice(): Promise<void> {
    if (this.device) {
      try {
        this.updateDeviceDetailsCall = pending;

        const details = new DeviceDetails();
        details.setIsMainDevice(this.isMainDevice);
        details.setDeviceName(this.deviceName);

        const request = new UpdateDeviceDetailsRequest();
        request.setDeviceId(this.device.getId());
        request.setSpaceId(spaces.selectedSpaceId);
        request.setDeviceDetails(details);

        await deviceServiceClient.updateDeviceDetails(request, {});

        this.device.setName(this.deviceName);
        this.device.setIsMainDevice(this.isMainDevice);

        this.updateDeviceDetailsCall = success(void 0);
        devices.getDevices();
      } catch (error) {
        this.updateDeviceDetailsCall = failure(userErrorFrom(error));
      }
    }
  }

  updateManualReadings() {
    this.$router.push(`${paths.platform.updateManualReadings}/${this.device?.getId()}`);
  }

  copyUrl(): void {
    navigator.clipboard.writeText(this.ocppWebsocketURL);
    this.copied = true;
  }

  get ocppWebsocketURL(): string {
    return (
      this.device
        ?.getMetaDataList()
        .find((x) => x.getKind() === MetaDataKind.OCPP_WEBSOCKET_URL)
        ?.getValue() ?? ""
    );
  }
}
