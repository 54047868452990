
import { paths } from "@/router/routes";
import { RemoteData, failure, initialized, initializedFactory, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { SearchParams } from "../devices-table/DevicesTable.vue";
import { LandlordToTenantProject } from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
import { GetLandlordToTenantProjectsRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";

export interface ProjectTable {
  id: string;
  name: string;
  location: string;
}

export interface ProjectTableControl {
  icon: string;
  event: string;
  color: string;
}

@Component({
  components: {},
})
export default class ProjectsTable extends Vue {
  @Prop({ default: false }) isRefreshProjects: boolean;
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  partnerProjects: RemoteData<UserError, LandlordToTenantProject[]> = initialized;
  projectPages: RemoteData<UserError, number> = initialized;

  searchParams: SearchParams = {
    page: 1,
    search: "",
  };

  headerControls = [
    {
      icon: "mdi-plus-circle",
      event: "create-new-project",
      color: "primary",
    },
  ];

  page: number = 1;
  search: string = "";
  itemsPerPage = 10;

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    if (this.selectedChildPartner.data) {
      this.loadProjects();
    }
  }

  @Watch("isRefreshProjects")
  onRefresh(): void {
    this.loadProjects();
  }

  mounted(): void {
    const pageQuery = this.$route.query.page;
    this.search = (this.$route.query.search as string) ?? "";
    this.page = typeof pageQuery === "string" ? parseInt(pageQuery, 10) : 1;
    this.loadProjects();
  }

  onSearchChanged(search: string): void {
    this.search = search;
    setTimeout(() => this.updateRoute(), 500);
  }
  @Watch("page")
  onPageChanged(): void {
    this.updateRoute();
  }

  get headers() {
    return [
      { text: "Name", value: "name", sortable: false },
      { text: "Adresse", value: "location", sortable: false },
      { text: "", value: "controls", sortable: false },
    ];
  }

  get projects(): ProjectTable[] {
    return this.partnerProjects.list.map((p: LandlordToTenantProject) => ({
      id: p.getId(),
      location: p.getLocation(),
      name: p.getName(),
    }));
  }

  get totalPages(): number {
    return this.projectPages?.data ?? 1;
  }

  handleRowClick(project: { id: string }): void {
    this.$router.push({
      path: `${paths.admin.mieterstrom}/${project.id}`,
    });
  }

  onItemControlsClicked(event: Event, project: ProjectTable, customEvent: string) {
    event.stopPropagation();
    this.$emit(customEvent, project.id);
  }

  onHeaderControlsClicked(event: string) {
    this.$emit(event);
  }

  updateRoute(): void {
    const search = this.$route.query.search;
    const page = this.$route.query.page;

    if (this.page.toString() !== page || search !== this.search) {
      this.$router.push({
        path: paths.admin.mieterstrom,
        query: { page: this.page.toString(), search: this.search },
      });

      this.loadProjects();
    }
  }

  async loadProjects(): Promise<void> {
    this.partnerProjects = pending;
    const request = new GetLandlordToTenantProjectsRequest();
    request.setPageSize(10);
    request.setPage(this.page - 1);

    if (this.search != "") {
      request.setSearchFilter(this.search);
    }

    if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());
    try {
      const response = await landlordToTenantServiceClient.getLandlordToTenantProjects(request, {});
      this.partnerProjects = success(response.getProjectsList());
      this.projectPages = success(response.getTotalNumPages());
    } catch (error) {
      this.partnerProjects = failure(userErrorFrom(error));
      this.projectPages = failure(userErrorFrom(error));
    }
  }
}
